export const GET_ERRORS = "GET_ERRORS";

//Block
export const GET_BLOCK = "GET_BLOCK";
export const GET_BLOCKS = "GET_BLOCKS";
export const GET_FAVORITE_BLOCKS = "GET_FAVORITE_BLOCKS";
export const DELETE_BLOCK = "DELETE_BLOCK";
export const CLEAR_BLOCK = "CLEAR_BLOCK";
export const CLEAR_BLOCKS = "CLEAR_BLOCKS";

export const ADD_BLOCK_REQUEST = "ADD_BLOCK_REQUEST";
export const ADD_BLOCK_SUCCESS = "ADD_BLOCK_SUCCESS";
export const ADD_BLOCK_ERROR = "ADD_BLOCK_ERROR";

export const UPDATE_BLOCK_REQUEST = "UPDATE_BLOCK_REQUEST";
export const UPDATE_BLOCK_SUCCESS = "UPDATE_BLOCK_SUCCESS";
export const UPDATE_BLOCK_ERROR = "UPDATE_BLOCK_ERROR";

export const UPDATE_BLOCK_DATA_REQUEST = "UPDATE_BLOCK_DATA_REQUEST";
export const UPDATE_BLOCK_DATA_SUCCESS = "UPDATE_BLOCK_DATA_SUCCESS";
export const UPDATE_BLOCK_DATA_ERROR = "UPDATE_BLOCK_DATA_ERROR";

export const UPDATE_BLOCK_PROGRESS_REQUEST = "UPDATE_BLOCK_PROGRESS_REQUEST";
export const UPDATE_BLOCK_PROGRESS_SUCCESS = "UPDATE_BLOCK_PROGRESS_SUCCESS";
export const UPDATE_BLOCK_PROGRESS_ERROR = "UPDATE_BLOCK_PROGRESS_ERROR";

export const GET_BLOCKS_REQUEST = "GET_BLOCKS_REQUEST";
export const GET_BLOCKS_SUCCESS = "GET_BLOCKS_SUCCESS";
export const GET_BLOCKS_ERROR = "GET_BLOCKS_ERROR";

export const GET_BLOCKS_SUBFLOW_REQUEST = "GET_BLOCKS_SUBFLOW_REQUEST";
export const GET_BLOCKS_SUBFLOW_SUCCESS = "GET_BLOCKS_SUBFLOW_SUCCESS";
export const GET_BLOCKS_SUBFLOW_ERROR = "GET_BLOCKS_SUBFLOW_ERROR";
export const CLEAR_BLOCKS_WITH_SUBFLOW = "CLEAR_BLOCK_WITH_SUBFLOW";

export const GET_BLOCKS_DEPS_REQUEST = "GET_BLOCKS_DEPS_REQUEST";
export const GET_BLOCKS_DEPS_SUCCESS = "GET_BLOCKS_DEPS_SUCCESS";
export const GET_BLOCKS_DEPS_ERROR = "GET_BLOCKS_DEPS_ERROR";

export const GET_FAVORITE_BLOCKS_REQUEST = "GET_FAVORITE_BLOCKS_REQUEST";
export const GET_FAVORITE_BLOCKS_SUCCESS = "GET_FAVORITE_BLOCKS_SUCCESS";
export const GET_FAVORITE_BLOCKS_ERROR = "GET_FAVORITE_BLOCKS_ERROR";

export const UPDATE_BLOCKS_REQUEST = "UPDATE_BLOCKS_REQUEST";
export const UPDATE_BLOCKS_SUCCESS = "UPDATE_BLOCKS_SUCCESS";
export const UPDATE_BLOCKS_ERROR = "UPDATE_BLOCKS_ERROR";

export const COPY_BLOCKS_REQUEST = "COPY_BLOCK_REQUEST";
export const COPY_BLOCKS_SUCCESS = "COPY_BLOCK_SUCCESS";

export const MOVE_BLOCK_REQUEST = "MOVE_BLOCK_REQUEST";
export const MOVE_BLOCK_SUCCESS = "MOVE_BLOCK_SUCCESS";
export const MOVE_BLOCK_ERROR = "MOVE_BLOCK_ERROR";

export const MOVE_BLOCK_FAVORITE_REQUEST = "MOVE_BLOCK_FAVORITE_REQUEST";
export const MOVE_BLOCK_FAVORITE_SUCCESS = "MOVE_BLOCK_FAVORITE_SUCCESS";
export const MOVE_BLOCK_FAVORITE_ERROR = "MOVE_BLOCK_FAVORITE_ERROR";

export const CONVERT_BLOCK_ACTIVITY_TO_BLOCK =
  "CONVERT_BLOCK_ACTIVITY_TO_BLOCK";
export const UPDATE_BLOCK_ACTIVITY = "UPDATE_BLOCK_ACTIVITY";

export const GET_GRID_BLOCKS_REQUEST = "GET_GRID_BLOCKS_REQUEST";
export const GET_GRID_BLOCKS_SUCCESS = "GET_GRID_BLOCKS_SUCCESS";
export const DELETE_BLOCK_ON_MAPPING_GRID = "DELETE_BLOCK_ON_MAPPING_GRID";
export const UPDATE_NAME_ON_MAPPING_GRID = "UPDATE_NAME_ON_MAPPING_GRID";
export const ADD_RECORD_ON_MAPPING_GRID = "ADD_RECORD_ON_MAPPING_GRID";
export const MOVE_RECORD_ON_MAPPING_GRID = "MOVE_RECORD_ON_MAPPING_GRID";

//Activity
export const GET_ACTIVITY = "GET_ACTIVITY";

export const GET_ACTIVITIES_REQUEST = "GET_ACTIVITIES_REQUEST";
export const GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS";
export const GET_ACTIVITIES_ERROR = "GET_ACTIVITIES_ERROR";

export const GET_ACTIVITIES_DEPS_REQUEST = "GET_ACTIVITIES_DEPS_REQUEST";
export const GET_ACTIVITIES_DEPS_SUCCESS = "GET_ACTIVITIES_DEPS_SUCCESS";
export const GET_ACTIVITIES_DEPS_ERROR = "GET_ACTIVITIES_DEPS_ERROR";

export const GET_INTER_ACT_DEPS_REQUEST = "GET_INTER_ACT_DEPS_REQUEST";
export const GET_INTER_ACT_DEPS_SUCCESS = "GET_INTER_ACT_DEPS_SUCCESS";
export const GET_INTER_ACT_DEPS_ERROR = "GET_INTER_ACT_DEPS_ERROR";

export const DELETE_ACTIVITY = "DELETE_ACTIVITY";

export const CREATE_ACTIVITY_REQUEST = "CREATE_ACTIVITY_REQUEST";
export const CREATE_ACTIVITY_SUCCESS = "CREATE_ACTIVITY_SUCCESS";
export const CREATE_ACTIVITY_DEPS_SUCCESS = "CREATE_ACTIVITY_DEPS_SUCCESS";

export const CREATE_ACTIVITY_ERROR = "CREATE_ACTIVITY_ERROR";

export const UPDATE_ACTIVITIES_REQUEST = "UPDATE_ACTIVITIES_REQUEST";
export const UPDATE_ACTIVITIES_SUCCESS = "UPDATE_ACTIVITIES_SUCCESS";

export const UPDATE_ACTIVITY_DATA_REQUEST = "UPDATE_ACTIVITY_DATA_REQUEST";
export const UPDATE_ACTIVITY_DATA_SUCCESS = "UPDATE_ACTIVITY_DATA_SUCCESS";
export const UPDATE_ACTIVITY_DATA_ERROR = "UPDATE_ACTIVITY_DATA_ERROR";

export const UPDATE_ACTIVITY_NAME_REQUEST = "UPDATE_ACTIVITY_NAME_REQUEST";
export const UPDATE_ACTIVITY_NAME_SUCCESS = "UPDATE_ACTIVITY_NAME_SUCCESS";
export const UPDATE_ACTIVITY_NAME_ERROR = "UPDATE_ACTIVITY_NAME_ERROR";

export const UPDATE_ACTIVITY_PROGRESS_REQUEST =
  "UPDATE_ACTIVITY_PROGRESS_REQUEST";
export const UPDATE_ACTIVITY_PROGRESS_SUCCESS =
  "UPDATE_ACTIVITY_PROGRESS_SUCCESS";
export const UPDATE_ACTIVITY_PROGRESS_ERROR = "UPDATE_ACTIVITY_PROGRESS_ERROR";

export const MOVE_ACTIVITY_REQUEST = "MOVE_ACTIVITY_REQUEST";
export const MOVE_ACTIVITY_SUCCESS = "MOVE_ACTIVITY_SUCCESS";
export const MOVE_ACTIVITY_ERROR = "MOVE_ACTIVITY_ERROR";

export const UPDATE_PROGRESS_OF_ACCUM_ACTIVITIES =
  "UPDATE_PROGRESS_OF_ACCUM_ACTIVITIES";
export const CLEAR_ACCUM_ACTIVITIES = "CLEAR_ACCUM_ACTIVITIES";

export const GET_ALL_ACTIVITIES_REQUEST = "GET_ALL_ACTIVITIES_REQUEST";
export const GET_ALL_ACTIVITIES_SUCCESS = "GET_ALL_ACTIVITIES_SUCCESS";
export const GET_ALL_ACTIVITIES_ERROR = "GET_ALL_ACTIVITIES_ERROR";
export const DELETE_ACTIVITY_ON_MAPPING_GRID =
  "DELETE_ACTIVITY_ON_MAPPING_GRID";
export const REFETCH_ACTIVITY = "REFETCH_ACTIVITY";

//Pattern

export const CREATE_PATTERN_REQUEST = "CREATE_PATTERN_REQUEST";
export const CREATE_PATTERN_SUCCESS = "CREATE_PATTERN_SUCCESS";
export const CREATE_PATTERN_ERROR = "CREATE_PATTERN_ERROR";

export const GET_PATTERNS_REQUEST = "GET_PATTERNS_REQUEST";
export const GET_PATTERNS_SUCCESS = "GET_PATTERNS_SUCCESS";
export const GET_PATTERNS_ERROR = "GET_PATTERNS_ERROR";

export const GET_PATTERN_REQUEST = "GET_PATTERN_REQUEST";
export const GET_PATTERN_SUCCESS = "GET_PATTERN_SUCCESS";
export const GET_PATTERN_ERROR = "GET_PATTERN_ERROR";

export const UPDATE_PATTERN_REQUEST = "UPDATE_PATTERN_REQUEST";
export const UPDATE_PATTERN_SUCCESS = "UPDATE_PATTERN_SUCCESS";
export const UPDATE_PATTERN_ERROR = "UPDATE_PATTERN_ERROR";

export const DELETE_PATTERN_REQUEST = "DELETE_PATTERN_REQUEST";
export const DELETE_PATTERN_SUCCESS = "DELETE_PATTERN_SUCCESS";
export const DELETE_PATTERN_ERROR = "DELETE_PATTERN_ERROR";

export const MOVE_PATTERN_REQUEST = "MOVE_PATTERN_REQUEST";
export const MOVE_PATTERN_SUCCESS = "MOVE_PATTERN_SUCCESS";
export const MOVE_PATTERN_ERROR = "MOVE_PATTERN_ERROR";

export const CONNECT_PATTERN_LINK_REQUEST = "CONNECT_PATTERN_LINK_REQUEST";
export const CONNECT_PATTERN_LINK_SUCCESS = "CONNECT_PATTERN_LINK_SUCCESS";
export const CONNECT_PATTERN_LINK_ERROR = "CONNECT_PATTERN_LINK_ERROR";

export const GET_PATTERN_COMBINATIONS_REQUEST =
  "GET_PATTERN_COMBINATIONS_REQUEST";
export const GET_PATTERN_COMBINATIONS_SUCCESS =
  "GET_PATTERN_COMBINATIONS_SUCCESS";
export const GET_PATTERN_COMBINATIONS_ERROR = "GET_PATTERN_COMBINATIONS_ERROR";

export const CREATE_PATTERN_COMBINATION_REQUEST =
  "CREATE_PATTERN_COMBINATION_REQUEST";
export const CREATE_PATTERN_COMBINATION_SUCCESS =
  "CREATE_PATTERN_COMBINATION_SUCCESS";
export const CREATE_PATTERN_COMBINATION_ERROR =
  "CREATE_PATTERN_COMBINATION_ERROR";

export const DELETE_PATTERN_COMBINATION_REQUEST =
  "DELETE_PATTERN_COMBINATION_REQUEST";
export const DELETE_PATTERN_COMBINATION_SUCCESS =
  "DELETE_PATTERN_COMBINATION_SUCCESS";
export const DELETE_PATTERN_COMBINATION_ERROR =
  "DELETE_PATTERN_COMBINATION_ERROR";

// Project

export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_ERROR = "CREATE_PROJECT_ERROR";

export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_ERROR = "GET_PROJECTS_ERROR";

export const GET_ACTIVE_PROJECTS_REQUEST = "GET_ACTIVE_PROJECTS_REQUEST";
export const GET_ACTIVE_PROJECTS_SUCCESS = "GET_ACTIVE_PROJECTS_SUCCESS";
export const GET_ACTIVE_PROJECTS_ERROR = "GET_ACTIVE_PROJECTS_ERROR";

export const GET_PROJECTS_SCENARIO_REQUEST = "GET_PROJECTS_SCENARIO_REQUEST";
export const GET_PROJECTS_SCENARIO_SUCCESS = "GET_PROJECTS_SCENARIO_SUCCESS";
export const GET_PROJECTS_SCENARIO_ERROR = "GET_PROJECTS_SCENARIO_ERROR";
export const CLEAR_PROJECTS_SCENARIO = "CLEAR_PROJECTS_SCENARIO";

export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_ERROR = "UPDATE_PROJECT_ERROR";

export const UPDATE_PROJECTS_ROW_INDEX_REQUEST =
  "UPDATE_PROJECTS_ROW_INDEX_REQUEST";
export const UPDATE_PROJECTS_ROW_INDEX_SUCCESS =
  "UPDATE_PROJECTS_ROW_INDEX_SUCCESS";
export const UPDATE_PROJECTS_ROW_INDEX_ERROR =
  "UPDATE_PROJECTS_ROW_INDEX_ERROR";

export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const GET_SCHEDULE_ACTIVITIES = "GET_SCHEDULE_ACTIVITIES";

export const GET_PROJECTS_DASHBOARD_REQUEST = "GET_PROJECTS_DASHBOARD_REQUEST";
export const GET_PROJECTS_DASHBOARD_SUCCESS = "GET_PROJECTS_DASHBOARD_SUCCESS";
export const GET_PROJECTS_DASHBOARD_ERROR = "GET_PROJECTS_DASHBOARD_ERROR";

export const GET_PROJECT_WITH_COLLABS_AND_ROLES_SUCCESS =
  "GET_PROJECT_WITH_COLLABS_AND_ROLES_SUCCESS";

export const UPDATE_PROJECT_COLLABORATORS = "UPDATE_PROJECT_COLLABORATORS";
export const GET_ALL_EXISTING_SUB_CODES = "GET_ALL_EXISTING_SUB_CODES";
export const UPDATE_CLOSED_STATUS_OF_SUB_CODES =
  "UPDATE_CLOSED_STATUS_OF_SUB_CODES";
export const SET_ACTIVE_PROJECT = "SET_ACTIVE_PROJECT";
export const PROPAGATE_PROJECT_ROLE = "PROPAGATE_PROJECT_ROLE";
export const UPDATE_PROJECT_WORK_PACKAGES = "UPDATE_PROJECT_WORK_PACKAGES";

// Programs
export const GET_PROGRAMS_REQUEST = "GET_PROGRAMS_REQUEST";
export const GET_PROGRAMS_SUCCESS = "GET_PROGRAMS_SUCCESS";
export const GET_PROGRAMS_ERROR = "GET_PROGRAMS_ERROR";
export const DELETE_PROGRAM = "DELETE_PROGRAM";
export const CREATE_PROJECT_WITH_PROGRAM_SUCCESS =
  "CREATE_PROJECT_WITH_PROGRAM_SUCCESS";
export const APPEND_PROJECTS_TO_PROGRAM = "APPEND_PROJECTS_TO_PROGRAM";
export const CREATE_PROGRAM = "CREATE_PROGRAM";
export const ADD_PROGRAM_IF_DOES_NOT_EXIST = "ADD_PROGRAM_IF_DOES_NOT_EXIST";
export const ADD_PROJECT = "ADD_PROJECT";
export const GET_PROGRAM_WITH_GANTT_DATA = "GET_PROGRAM_WITH_GANTT_DATA";
export const UPDATE_PROGRAMS_REQUEST = "UPDATE_PROGRAMS_REQUEST";
export const UPDATE_PROGRAMS_ERROR = "UPDATE_PROGRAMS_ERROR";
export const REFETCH_PROGRAM_GANTT_DATA = "REFETCH_PROGRAM_GANTT_DATA";
export const GET_PROGRAM_WITH_TIMELINE_DATA = "GET_PROGRAM_WITH_TIMELINE_DATA";
export const SWAP_SCENARIO_ON_PROGRAM_GANTT_DATA =
  "SWAP_SCENARIO_ON_PROGRAM_GANTT_DATA";

// Project Objective

export const CREATE_OBJECTIVE_REQUEST = "CREATE_OBJECTIVE_REQUEST";
export const CREATE_OBJECTIVE_SUCCESS = "CREATE_OBJECTIVE_SUCCESS";
export const CREATE_OBJECTIVE_ERROR = "CREATE_OBJECTIVE_ERROR";

export const GET_OBJECTIVES_REQUEST = "GET_OBJECTIVES_REQUEST";
export const GET_OBJECTIVES_SUCCESS = "GET_OBJECTIVES_SUCCESS";
export const GET_OBJECTIVES_ERROR = "GET_OBJECTIVES_ERROR";

export const GET_OBJECTIVE_REQUEST = "GET_OBJECTIVE_REQUEST";
export const GET_OBJECTIVE_SUCCESS = "GET_OBJECTIVE_SUCCESS";
export const GET_OBJECTIVE_ERROR = "GET_OBJECTIVE_ERROR";

export const UPDATE_OBJECTIVE_REQUEST = "UPDATE_OBJECTIVE_REQUEST";
export const UPDATE_OBJECTIVE_SUCCESS = "UPDATE_OBJECTIVE_SUCCESS";
export const UPDATE_OBJECTIVE_ERROR = "UPDATE_OBJECTIVE_ERROR";

export const DELETE_OBJECTIVE_REQUEST = "DELETE_OBJECTIVE_REQUEST";
export const DELETE_OBJECTIVE_SUCCESS = "DELETE_OBJECTIVE_SUCCESS";
export const DELETE_OBJECTIVE_ERROR = "DELETE_OBJECTIVE_ERROR";

//Schedule Activity
export const DELETE_SCHEDULE_ACTIVITY = "DELETE_SCHEDULE_ACTIVITY";
export const DELETE_SCHEDULE_ACTIVITIES = "DELETE_SCHEDULE_ACTIVITIES";

export const GET_SCHEDULE_ACTIVITIES_REQUEST =
  "GET_SCHEDULE_ACTIVITIES_REQUEST";
export const GET_SCHEDULE_ACTIVITIES_SUCCESS =
  "GET_SCHEDULE_ACTIVITIES_SUCCESS";
export const GET_SCHEDULE_ACTIVITIES_ERROR = "GET_SCHEDULE_ACTIVITIES_ERROR";

export const UPDATE_SCHEDULE_ACTIVITIES_REQUEST =
  "UPDATE_SCHEDULE_ACTIVITIES_REQUEST";
export const UPDATE_SCHEDULE_ACTIVITIES_SUCCESS =
  "UPDATE_SCHEDULE_ACTIVITIES_SUCCESS";
export const UPDATE_SCHEDULE_ACTIVITIES_ERROR =
  "UPDATE_SCHEDULE_ACTIVITIES_ERROR";

export const UPDATE_SCHEDULE_ACTIVITIES_WITH_USAGE_REQUEST =
  "UPDATE_SCHEDULE_ACTIVITIES_WITH_USAGE_REQUEST";
export const UPDATE_SCHEDULE_ACTIVITIES_WITH_USAGE_SUCCESS =
  "UPDATE_SCHEDULE_ACTIVITIES_WITH_USAGE_SUCCESS";
export const UPDATE_SCHEDULE_ACTIVITIES_WITH_USAGE_ERROR =
  "UPDATE_SCHEDULE_ACTIVITIES_WITH_USAGE_ERROR";

export const UPDATE_SCHEDULE_ACTIVITIES_IMPORT_SCOPE_REQUEST =
  "UPDATE_SCHEDULE_ACTIVITIES_IMPORT_SCOPE_REQUEST";
export const UPDATE_SCHEDULE_ACTIVITIES_IMPORT_SCOPE_SUCCESS =
  "UPDATE_SCHEDULE_ACTIVITIES_IMPORT_SCOPE_SUCCESS";
export const UPDATE_SCHEDULE_ACTIVITIES_IMPORT_SCOPE_ERROR =
  "UPDATE_SCHEDULE_ACTIVITIES_IMPORT_SCOPE_ERROR";

export const CLEAR_SCHEDULE_ACTIVITIES = "CLEAR_SCHEDULE_ACTIVITIES";

export const UPDATE_SCHEDULE_ACTIVITY_REQUEST =
  "UPDATE_SCHEDULE_ACTIVITY_REQUEST";
export const UPDATE_SCHEDULE_ACTIVITY_SUCCESS =
  "UPDATE_SCHEDULE_ACTIVITY_SUCCESS";
export const UPDATE_SCHEDULE_ACTIVITY_ERROR = "UPDATE_SCHEDULE_ACTIVITY_ERROR";

export const UPDATE_DETAIL_ACTIVITY_REQUEST = "UPDATE_DETAIL_ACTIVITY_REQUEST";
export const UPDATE_DETAIL_ACTIVITY_SUCCESS = "UPDATE_DETAIL_ACTIVITY_SUCCESS";
export const UPDATE_DETAIL_ACTIVITY_ERROR = "UPDATE_DETAIL_ACTIVITY_ERROR";

export const ADD_SCHEDULE_ACTIVITY_REQUEST = "ADD_SCHEDULE_ACTIVITY_REQUEST";
export const ADD_SCHEDULE_ACTIVITY_SUCCESS = "ADD_SCHEDULE_ACTIVITY_SUCCESS";
export const ADD_SCHEDULE_ACTIVITY_ERROR = "ADD_SCHEDULE_ACTIVITY_ERROR";
export const ADD_SCHEDULE_ACTIVITIES = "ADD_SCHEDULE_ACTIVITIES";

export const GET_REGULARS_REQUEST = "GET_REGULARS_REQUEST";
export const GET_REGULARS_SUCCESS = "GET_REGULARS_SUCCESS";
export const GET_REGULARS_ERROR = "GET_REGULARS_ERROR";

export const UPLOAD_MPP_REQUEST = "UPLOAD_MPP_REQUEST";
export const UPLOAD_MPP_SUCCESS = "UPLOAD_MPP_SUCCESS";
export const UPLOAD_MPP_ERROR = "UPLOAD_MPP_ERROR";

export const UPDATE_REGULAR = "UPDATE_REGULAR";

export const UPDATE_SCHEDULE_ACTIVITIES_BY_IDS =
  "UPDATE_SCHEDULE_ACTIVITIES_BY_IDS";

export const RESET_BASELINE_ON_ACTIVITY = "RESET_BASELINE_ON_ACTIVITY";
export const RESET_BASELINE_ON_SUMMARY = "RESET_BASELINE_ON_SUMMARY";
export const ADD_SUBTASK_SUCCESS = "ADD_SUBTASK_SUCCESS";
export const ADD_SUMMARY_SUCCESS = "ADD_SUMMARY_SUCCESS";
export const DUPLICATE_ACTIVITY_SUCCESS = "DUPLICATE_ACTIVITY_SUCCESS";
export const APPLY_CONFORM_PROGRESS = "APPLY_CONFORM_PROGRESS";

export const GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMNS_REQUEST =
  "GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMNS_REQUEST";
export const GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMNS_SUCCESS =
  "GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMNS_SUCCESS";
export const GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMNS_ERROR =
  "GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMNS_ERROR";
export const UPDATE_SCHEDULE_ACTIVITY_CUSTOM_COLUMN =
  "UPDATE_SCHEDULE_ACTIVITY_CUSTOM_COLUMN";
export const UPDATE_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_VALUE =
  "UPDATE_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_VALUE";

export const GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_NAMES_REQUEST =
  "GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_NAMES_REQUEST";
export const GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_NAMES_SUCCESS =
  "GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_NAMES_SUCCESS";
export const GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_NAMES_ERROR =
  "GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_NAMES_ERROR";
export const PROPAGATE_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_VALUE =
  "PROPAGATE_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_VALUE";

export const UPDATE_SCHEDULE_ACTIVITY_FINISH_DATE_WITH_DURATION_SUCCESS =
  "UPDATE_SCHEDULE_ACTIVITY_FINISH_DATE_WITH_DURATION_SUCCESS";
export const SHOW_MASK_ON_GANTT = "SHOW_MASK_ON_GANTT";
export const UPDATE_FINISH_AND_START = "UPDATE_FINISH_AND_START";

// Scenario
export const DELETE_SCENARIO_REQUEST = "DELETE_SCENARIO_REQUEST";
export const DELETE_SCENARIO_SUCCESS = "DELETE_SCENARIO_SUCCESS";
export const DELETE_SCENARIO_ERROR = "DELETE_SCENARIO_ERROR";

export const CREATE_SCENARIO_REQUEST = "CREATE_SCENARIO_REQUEST";
export const CREATE_SCENARIO_SUCCESS = "CREATE_SCENARIO_SUCCESS";
export const CREATE_SCENARIO_ERROR = "CREATE_SCENARIO_ERROR";

export const GET_SCENARIOS_REQUEST = "GET_SCENARIOS_REQUEST";
export const GET_SCENARIOS_SUCCESS = "GET_SCENARIOS_SUCCESS";
export const GET_SCENARIOS_ERROR = "GET_SCENARIOS_ERROR";

export const GET_SCENARIOS_SUCCESS_AND_SET_ACTIVE_SCENARIO =
  "GET_SCENARIOS_SUCCESS_AND_SET_ACTIVE_SCENARIO";

export const UPDATE_SCENARIO_REQUEST = "UPDATE_SCENARIO_REQUEST";
export const UPDATE_SCENARIO_SUCCESS = "UPDATE_SCENARIO_SUCCESS";
export const UPDATE_SCENARIO_ERROR = "UPDATE_SCENARIO_ERROR";

export const COPY_SCENARIO_REQUEST = "COPY_SCENARIO_REQUEST";
export const COPY_SCENARIO_SUCCESS = "COPY_SCENARIO_SUCCESS";
export const COPY_SCENARIO_ERROR = "COPY_SCENARIO_ERROR";

export const UPDATE_SCENARIOS_REQUEST = "UPDATE_SCENARIOS_REQUEST";
export const UPDATE_SCENARIOS_SUCCESS = "UPDATE_SCENARIOS_SUCCESS";
export const UPDATE_SCENARIOS_ERROR = "UPDATE_SCENARIOS_ERROR";

export const GENERATE_SCHEDULE_REQUEST = "GENERATE_SCHEDULE_REQUEST";
export const GENERATE_SCHEDULE_SUCCESS = "GENERATE_SCHEDULE_SUCCESS";
export const GENERATE_SCHEDULE_ERROR = "GENERATE_SCHEDULE_ERROR";

export const GET_SCENARIO_REQUEST = "GET_SCENARIO_REQUEST";
export const GET_SCENARIO_SUCCESS = "GET_SCENARIO_SUCCESS";
export const GET_SCENARIO_ERROR = "GET_SCENARIO_ERROR";

export const GET_SCENARIO_METRIC_REQUEST = "GET_SCENARIO_METRIC_REQUEST";
export const GET_SCENARIO_METRIC_SUCCESS = "GET_SCENARIO_METRIC_SUCCESS";
export const GET_SCENARIO_METRIC_ERROR = "GET_SCENARIO_METRIC_ERROR";

export const UPDATE_CURRENT_SCENARIO_REQUEST =
  "UPDATE_CURRENT_SCENARIO_REQUEST";
export const UPDATE_CURRENT_SCENARIO_SUCCESS =
  "UPDATE_CURRENT_SCENARIO_SUCCESS";
export const UPDATE_CURRENT_SCENARIO_ERROR = "UPDATE_CURRENT_SCENARIO_ERROR";

export const UPDATE_DOR_WITH_CONFORM_PROGRESS_SUCCESS =
  "UPDATE_DOR_WITH_CONFORM_PROGRESS_SUCCESS";

export const CLEAR_CURRENT_SCENARIO = "CLEAR_CURRENT_SCENARIO";
export const SET_CURRENT_SCENARIO = "SET_CURRENT_SCENARIO";

export const UPDATE_SCENARIO_STATE = "UPDATE_SCENARIO_STATE";

export const CONVERT_TO_AUTOMATIC_REQUEST = "CONVERT_TO_AUTOMATIC_REQUEST";
export const CONVERT_TO_AUTOMATIC_SUCCESS = "CONVERT_TO_AUTOMATIC_SUCCESS";
export const CONVERT_TO_AUTOMATIC_ERROR = "CONVERT_TO_AUTOMATIC_ERROR";

export const GET_SWAP_SCENARIOS_REQUEST = "GET_SWAP_SCENARIOS_REQUEST";
export const GET_SWAP_SCENARIOS_SUCCESS = "GET_SWAP_SCENARIOS_SUCCESS";
export const GET_SWAP_SCENARIOS_ERROR = "GET_SWAP_SCENARIOS_ERROR";

export const GET_TIMELINE_NON_LABOR_RESOURCE_IDS_SUCCESS =
  "GET_TIMELINE_NON_LABOR_RESOURCE_IDS_SUCCESS";
export const GET_TIMELINE_NON_LABOR_RESOURCE_IDS_ERROR =
  "GET_TIMELINE_NON_LABOR_RESOURCE_IDS_ERROR";

export const GET_EVM_REQUEST = "GET_EVM_REQUEST";
export const GET_EVM_SUCCESS = "GET_EVM_SUCCESS";
export const GET_EVM_ERROR = "GET_EVM_ERROR";
export const GET_SUB_CODES_OF_PROJECT = "GET_SUB_CODES_OF_PROJECT";
export const RESET_ASSUMPTIONS = "RESET_ASSUMPTIONS";

export const SET_ACTIVE_SCENARIO = "SET_ACTIVE_SCENARIO";

//User
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_USER_OUT = "SET_CURRENT_USER_OUT";

//Insights
export const GET_CHAPTER_DATA = "GET_CHAPTER_DATA";
export const GET_PACKAGE_DATA = "GET_PACKAGE_DATA";
export const GET_AVERAGE_DURATION = "GET_AVERAGE_DURATION";
export const GET_AVERAGE_WORK = "GET_AVERAGE_WORK";
export const GET_AVERAGE_COST = "GET_AVERAGE_COST";
export const GET_AVERAGE_FTE = "GET_AVERAGE_FTE";
export const GET_RELIABILITY_SCORE = "GET_RELIABILITY_SCORE";
export const GET_CHANCE_OF_SUCCESS = "GET_CHANCE_OF_SUCCESS";
export const GET_CHANCE_OF_SUCCESS_REQUEST = "GET_CHANCE_OF_SUCCESS_REQUEST";
export const GET_CHANCE_OF_SUCCESS_SUCCESS = "GET_CHANCE_OF_SUCCESS_SUCCESS";
export const GET_CHANCE_OF_SUCCESS_ERROR = "GET_CHANCE_OF_SUCCESS_ERROR";

//Structure
export const GET_LIBRARIES_REQUEST = "GET_LIBRARIES_REQUEST";
export const GET_LIBRARIES_SUCCESS = "GET_LIBRARIES_SUCCESS";
export const GET_LIBRARIES_ERROR = "GET_LIBRARIES_ERROR";

export const CREATE_LIBRARY_REQUEST = "CREATE_LIBRARY_REQUEST";
export const CREATE_LIBRARY_SUCCESS = "CREATE_LIBRARY_SUCCESS";
export const CREATE_LIBRARY_ERROR = "CREATE_LIBRARY_ERROR";

export const EDIT_LIBRARY_REQUEST = "EDIT_LIBRARY_REQUEST";
export const EDIT_LIBRARY_SUCCESS = "EDIT_LIBRARY_SUCCESS";
export const EDIT_LIBRARY_ERROR = "EDIT_LIBRARY_ERROR";

export const DUPLICATE_LIBRARY_REQUEST = "DUPLICATE_LIBRARY_REQUEST";
export const DUPLICATE_LIBRARY_SUCCESS = "DUPLICATE_LIBRARY_SUCCESS";
export const DUPLICATE_LIBRARY_ERROR = "DUPLICATE_LIBRARY_ERROR";

export const DELETE_LIBRARY_REQUEST = "DELETE_LIBRARY_REQUEST";
export const DELETE_LIBRARY_SUCCESS = "DELETE_LIBRARY_SUCCESS";
export const DELETE_LIBRARY_ERROR = "DELETE_LIBRARY_ERROR";

export const GET_STRUCTURES = "GET_STRUCTURES";
export const GET_STRUCTURES_SUCCESS = "GET_STRUCTURES_SUCCESS";
export const GET_STRUCTURES_REQUEST = "GET_STRUCTURES_REQUEST";
export const GET_STRUCTURES_ERROR = "GET_STRUCTURES_ERROR";

export const CREATE_STRUCTURES_REQUEST = "CREATE_STRUCTURES_REQUEST";
export const CREATE_STRUCTURES_SUCCESS = "CREATE_STRUCTURES_SUCCESS";
export const CREATE_STRUCTURES_ERROR = "CREATE_STRUCTURES_ERROR";

export const UPDATE_STRUCTURE_SUCCESS = "UPDATE_STRUCTURE_SUCCESS";

export const IMPORT_CSV_STRUCTURE_REQUEST = "IMPORT_CSV_STRUCTURE_REQUEST";
export const IMPORT_CSV_STRUCTURE_SUCCESS = "IMPORT_CSV_STRUCTURE_SUCCESS";
export const IMPORT_CSV_STRUCTURE_ERROR = "IMPORT_CSV_STRUCTURE_ERROR";

export const GET_STRUCTURE = "GET_STRUCTURE";
export const DELETE_STRUCTURE = "DELETE_STRUCTURE";
export const STRUCTURE_LOADER = "STRUCTURE_LOADER";

export const GET_LIBRARIES_WITH_CAPEX_REQUEST =
  "GET_LIBRARIES_WITH_CAPEX_REQUEST";
export const GET_LIBRARIES_WITH_CAPEX_SUCCESS =
  "GET_LIBRARIES_WITH_CAPEX_SUCCESS";
export const GET_LIBRARIES_WITH_CAPEX_ERROR = "GET_LIBRARIES_WITH_CAPEX_ERROR";

export const DUPLICATE_STRUCTURE_REQUEST = "DUPLICATE_STRUCTURE_REQUEST";
export const DUPLICATE_STRUCTURE_SUCCESS = "DUPLICATE_STRUCTURE_SUCCESS";
export const DUPLICATE_STRUCTURE_ERROR = "DUPLICATE_STRUCTURE_ERROR";

//Office
export const GET_COUNT_PATTERNS = "GET_COUNT_PATTERNS";
export const GET_COUNT_STRUCTURES = "GET_COUNT_STRUCTURES";
export const GET_COUNT_PROJECTS = " GET_COUNT_PROJECTS";

//Dependency
export const GET_DEPENDENCIES = "GET_DEPENDENCIES";
export const GET_DEPENDENCIES_FOR_SANDKEY = "GET_DEPENDENCIES_FOR_SANDKEY";
export const GET_DEPENDENCY = "GET_DEPENDENCY";
export const DELETE_DEPENDENCY = "DELETE_DEPENDENCY";
export const CLEAR_DEPENDENCIES = "CLEAR_DEPENDENCIES";

export const CREATE_DEPENDENCY_REQUEST = "CREATE_DEPENDENCY_REQUEST";
export const CREATE_DEPENDENCY_SUCCESS = "CREATE_DEPENDENCY_SUCCESS";
export const CREATE_DEPENDENCY_ERROR = "CREATE_DEPENDENCY_ERROR";

export const CREATE_ACT_DEPS_REQUEST = "CREATE_ACT_DEPS_REQUEST";
export const CREATE_ACT_DEPS_SUCCESS = "CREATE_ACT_DEPS_SUCCESS";
export const CREATE_ACT_DEPS_ERROR = "CREATE_ACT_DEPS_ERROR";

export const GET_DEPENDENCIES_REQUEST = "GET_DEPENDENCIES_REQUEST";
export const GET_DEPENDENCIES_SUCCESS = "GET_DEPENDENCIES_SUCCESS";
export const GET_DEPENDENCIES_ERROR = "GET_DEPENDENCIES_ERROR";

export const GET_ALL_ACT_DEPENDENCIES_REQUEST =
  "GET_ALL_ACT_DEPENDENCIES_REQUEST";
export const GET_ALL_ACT_DEPENDENCIES_SUCCESS =
  "GET_ALL_ACT_DEPENDENCIES_SUCCESS";
export const GET_ALL_ACT_DEPENDENCIES_ERROR = "GET_ALL_ACT_DEPENDENCIES_ERROR";

export const GET_ACT_DEPS_REQUEST = "GET_ACT_DEPS_REQUEST";
export const GET_ACT_DEPS_SUCCESS = "GET_ACT_DEPS_SUCCESS";
export const GET_ACT_DEPS_ERROR = "GET_ACT_DEPS_ERROR";

export const UPDATE_DEPENDENCY_REQUEST = "UPDATE_DEPENDENCY_REQUEST";
export const UPDATE_DEPENDENCY_SUCCESS = "UPDATE_DEPENDENCY_SUCCESS";
export const UPDATE_DEPENDENCY_ERROR = "UPDATE_DEPENDENCY_ERROR";

export const DELETE_DEPENDENCY_REQUEST = "DELETE_DEPENDENCY_REQUEST";
export const DELETE_DEPENDENCY_SUCCESS = "DELETE_DEPENDENCY_SUCCESS";
export const DELETE_DEPENDENCY_ERROR = "DELETE_DEPENDENCY_ERROR";

export const CLEAR_ACCUM_ACTIVITY_DEPENDENCIES =
  "CLEAR_ACCUM_ACTIVITY_DEPENDENCIES";

// RESOURCES

export const CREATE_RESOURCE_REQUEST = "CREATE_RESOURCE_REQUEST";
export const CREATE_RESOURCE_SUCCESS = "CREATE_RESOURCE_SUCCESS";
export const CREATE_RESOURCE_ERROR = "CREATE_RESOURCE_ERROR";

export const CREATE_MANAGER_REQUEST = "CREATE_MANAGER_REQUEST";
export const CREATE_MANAGER_SUCCESS = "CREATE_MANAGER_SUCCESS";
export const CREATE_MANAGER_ERROR = "CREATE_MANAGER_ERROR";

export const CREATE_COLLABS_REQUEST = "CREATE_COLLABS_REQUEST";
export const CREATE_COLLABS_SUCCESS = "CREATE_COLLABS_SUCCESS";
export const CREATE_COLLABS_ERROR = "CREATE_COLLABS_ERROR";

export const UPDATE_RESOURCE_REQUEST = "UPDATE_RESOURCE_REQUEST";
export const UPDATE_RESOURCE_SUCCESS = "UPDATE_RESOURCE_SUCCESS";
export const UPDATE_RESOURCE_ERROR = "UPDATE_RESOURCE_ERROR";

export const GET_MAPPED_RESOURCES_REQUEST = "GET_MAPPED_RESOURCES_REQUEST";
export const GET_MAPPED_RESOURCES_SUCCESS = "GET_MAPPED_RESOURCES_SUCCESS";
export const GET_MAPPED_RESOURCES_ERROR = "GET_MAPPED_RESOURCES_ERROR";

export const GET_RESOURCES_BY_SCENARIO_ID_REQUEST =
  "GET_RESOURCES_BY_SCENARIO_ID_REQUEST";
export const GET_RESOURCES_BY_SCENARIO_ID_SUCCESS =
  "GET_RESOURCES_BY_SCENARIO_ID_SUCCESS";
export const GET_RESOURCES_BY_SCENARIO_ID_ERROR =
  "GET_RESOURCES_BY_SCENARIO_ID_ERROR";

export const GET_COLLABS_REQUEST = "GET_COLLABS_REQUEST";
export const GET_COLLABS_SUCCESS = "GET_COLLABS_SUCCESS";
export const GET_COLLABS_ERROR = "GET_COLLABS_ERROR";

export const GET_PORFOLIO_COLLABS_AND_SPECIFIC_RESOURCES_REQUEST =
  "GET_PORFOLIO_COLLABS_AND_SPECIFIC_RESOURCES_REQUEST";
export const GET_PORFOLIO_COLLABS_AND_SPECIFIC_RESOURCES_SUCCESS =
  "GET_PORFOLIO_COLLABS_AND_SPECIFIC_RESOURCES_SUCCESS";
export const GET_PORFOLIO_COLLABS_AND_SPECIFIC_RESOURCES_ERROR =
  "GET_PORFOLIO_COLLABS_AND_SPECIFIC_RESOURCES_ERROR";
export const GET_COLLABS_AND_SPECIFIC_RESOURCES_OF_USER_REQUEST =
  "GET_COLLABS_AND_SPECIFIC_RESOURCES_OF_USER_REQUEST";
export const GET_COLLABS_AND_SPECIFIC_RESOURCES_OF_USER_SUCCESS =
  "GET_COLLABS_AND_SPECIFIC_RESOURCES_OF_USER_SUCCESS";
export const GET_COLLABS_AND_SPECIFIC_RESOURCES_OF_USER_ERROR =
  "GET_COLLABS_AND_SPECIFIC_RESOURCES_OF_USER_ERROR";

export const GET_TEAMS_REQUEST = "GET_TEAMS_REQUEST";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_ERROR = "GET_TEAMS_ERROR";

export const GET_MY_TEAMS_REQUEST = "GET_MY_TEAMS_REQUEST";
export const GET_MY_TEAMS_SUCCESS = "GET_MY_TEAMS_SUCCESS";
export const GET_MY_TEAMS_ERROR = "GET_MY_TEAMS_ERROR";

export const DELETE_RESOURCE_REQUEST = "DELETE_RESOURCE_REQUEST";
export const DELETE_RESOURCE_SUCCESS = "DELETE_RESOURCE_SUCCESS";
export const DELETE_RESOURCE_ERROR = "DELETE_RESOURCE_ERROR";
export const CLEAR_RESOURCES = "CLEAR_RESOURCES";

export const GET_PORTFOLIO_USAGE = "GET_PORTFOLIO_USAGE";
export const GET_PORTFOLIO_CAPACITY = "GET_PORTFOLIO_CAPACITY";
export const GET_SCENARIO_USAGE = "GET_SCENARIO_USAGE";

export const DELETE_MANAGER = "DELETE_MANAGER";
export const DELETE_COLLAB = "DELETE_COLLAB";

export const RESET_WEEKLY_CAPACITY = "RESET_WEEKLY_CAPACITY";
export const ERASE_CAPACITY = "ERASE_CAPACITY";
export const GENERATE_TEAMS_WORKLOAD = "GENERATE_TEAMS_WORKLOAD";

export const UPDATE_SIMULATION_USAGE = "UPDATE_SIMULATION_USAGE";
export const SIMULATION_PROCESS = "SIMULATION_PROCESS";
export const SIMULATION_SUCCESS = "SIMULATION_SUCCESS";
export const SIMULATION_ERROR = "SIMULATION_ERROR";

export const RESET_USAGES = "RESET_USAGES";

export const GET_SPECIFIC_NON_LABOR_RESOURCES_REQUEST =
  "GET_SPECIFIC_NON_LABOR_RESOURCES_REQUEST";
export const GET_SPECIFIC_NON_LABOR_RESOURCES_SUCCESS =
  "GET_SPECIFIC_NON_LABOR_RESOURCES_SUCCESS";
export const GET_SPECIFIC_NON_LABOR_RESOURCES_ERROR =
  "GET_SPECIFIC_NON_LABOR_RESOURCES_ERROR";

export const MARK_FEVER_CHART_SUCCESS = "MARK_FEVER_CHART_SUCCESS";

export const GET_ALL_APPROVED_SCENARIOS_REQUEST =
  "GET_ALL_APPROVED_SCENARIOS_REQUEST";
export const GET_ALL_APPROVED_SCENARIOS_SUCCESS =
  "GET_ALL_APPROVED_SCENARIOS_SUCCESS";
export const GET_ALL_APPROVED_SCENARIOS_ERROR =
  "GET_ALL_APPROVED_SCENARIOS_ERROR";

// SHIFTS
export const CREATE_SHIFT_REQUEST = "CREATE_SHIFT_REQUEST";
export const CREATE_SHIFT_SUCCESS = "CREATE_SHIFT_SUCCESS";
export const CREATE_SHIFT_ERROR = "CREATE_SHIFT_ERROR";
export const FETCH_SHIFTS_REQUEST = "FETCH_SHIFTS_REQUEST";
export const FETCH_ALL_SHIFTS_SUCCESS = "FETCH_ALL_SHIFTS_SUCCESS";
export const FETCH_ALL_SHIFTS_ERROR = "FETCH_ALL_SHIFTS_ERROR";
export const DELETE_SHIFT_SUCCESS = "DELETE_SHIFT_SUCCESS";
export const DELETE_SHIFT_ERROR = "DELETE_SHIFT_ERROR";
export const UPDATE_SHIFTS_REQUEST = "UPDATE_SHIFTS_REQUEST";
export const UPDATE_SHIFTS_SUCCESS = "UPDATE_SHIFTS_SUCCESS";
export const UPDATE_SHIFTS_ERROR = "UPDATE_SHIFTS_ERROR";
export const MUTATE_SHIFTS_WITHOUT_RERENDER = "MUTATE_SHIFTS_WITHOUT_RERENDER";

//BUDGET ENVELOPES
export const FETCH_ALL_BUDGET_ENVELOPES_REQUEST =
  "FETCH_ALL_BUDGET_ENVELOPES_REQUEST";
export const FETCH_ALL_BUDGET_ENVELOPES_SUCCESS =
  "FETCH_ALL_BUDGET_ENVELOPES_SUCCESS";
export const FETCH_ALL_BUDGET_ENVELOPES_ERROR =
  "FETCH_ALL_BUDGET_ENVELOPES_ERROR";
export const UPDATE_BUDGET_ENVELOPE_REQUEST = "UPDATE_BUDGET_ENVELOPE_REQUEST";
export const UPDATE_BUDGET_ENVELOPE_SUCCESS = "UPDATE_BUDGET_ENVELOPE_SUCCESS";
export const UPDATE_BUDGET_ENVELOPE_ERROR = "UPDATE_BUDGET_ENVELOPE_ERROR";
export const ADD_MISSING_ENVELOPES = "ADD_MISSING_ENVELOPES";

// USAGE
export const GET_SCENARIO_USAGE_REQUEST = "GET_SCENARIO_USAGE_REQUEST";
export const GET_SCENARIO_USAGE_SUCCESS = "GET_SCENARIO_USAGE_SUCCESS";
export const GET_SCENARIO_USAGE_ERROR = "GET_SCENARIO_USAGE_ERROR";

export const GET_ACTUALS_REQUEST = "GET_ACTUALS_REQUEST";
export const GET_ACTUALS_SUCCESS = "GET_ACTUALS_SUCCESS";
export const GET_ACTUALS_ERROR = "GET_ACTUALS_ERROR";

export const GET_AVAILABILITY_REQUEST = "GET_AVAILABILITY_REQUEST";
export const GET_AVAILABILITY_SUCCESS = "GET_AVAILABILITY_SUCCESS";
export const GET_AVAILABILITY_ERROR = "GET_AVAILABILITY_ERROR";

export const GET_DIMENSION_RESOURCES_REQUEST =
  "GET_DIMENSION_RESOURCES_REQUEST";
export const GET_DIMENSION_RESOURCES_SUCCESS =
  "GET_DIMENSION_RESOURCES_SUCCESS";
export const GET_DIMENSION_RESOURCES_ERROR = "GET_DIMENSION_RESOURCES_ERROR";

export const CLEAR_USAGES = "CLEAR_USAGES";

export const GET_DYNAMIC_COLUMNS_REQUEST = "GET_DYNAMIC_COLUMNS_REQUEST";
export const GET_DYNAMIC_COLUMNS_SUCCESS = "GET_DYNAMIC_COLUMNS_SUCCESS";
export const GET_DYNAMIC_COLUMNS_ERROR = "GET_DYNAMIC_COLUMNS_ERROR";
export const GET_DYNAMIC_COLUMNS_AND_USAGES_OF_YEAR_REQUEST =
  "GET_DYNAMIC_COLUMNS_AND_USAGES_OF_YEAR_REQUEST";
export const GET_DYNAMIC_COLUMNS_AND_USAGES_OF_YEAR_SUCCESS =
  "GET_DYNAMIC_COLUMNS_AND_USAGES_OF_YEAR_SUCCESS";
export const GET_DYNAMIC_COLUMNS_AND_USAGES_OF_YEAR_ERROR =
  "GET_DYNAMIC_COLUMNS_AND_USAGES_OF_YEAR_ERROR";

export const IMPORT_ACTUALS_REQUEST = "IMPORT_ACTUALS_REQUEST";
export const IMPORT_ACTUALS_SUCCESS = "IMPORT_ACTUALS_SUCCESS";
export const IMPORT_ACTUALS_ERROR = "IMPORT_ACTUALS_ERROR";

export const ADD_ACTUALS_REQUEST = "ADD_ACTUALS_REQUEST";
export const ADD_ACTUALS_SUCCESS = "ADD_ACTUALS_SUCCESS";
export const ADD_ACTUALS_ERROR = "ADD_ACTUALS_ERROR";

export const UPDATE_USAGE_REQUEST = "UPDATE_USAGE_REQUEST";
export const UPDATE_USAGE_SUCESS = "UPDATE_USAGE_SUCCESS";
export const UPDATE_USAGE_ERROR = "UPDATE_USAGE_ERROR";

export const UPDATE_USAGES_REQUEST = "UPDATE_USAGES_REQUEST";
export const UPDATE_USAGES_SUCESS = "UPDATE_USAGES_SUCCESS";
export const UPDATE_USAGES_ERROR = "UPDATE_USAGES_ERROR";

export const GET_USAGE_BY_RESOURCE_REQUEST = "GET_USAGE_BY_RESOURCE_REQUEST";
export const GET_USAGE_BY_RESOURCE_SUCCESS = "GET_USAGE_BY_RESOURCE_SUCCESS";
export const GET_USAGE_BY_RESOURCE_ERROR = "GET_USAGE_BY_RESOURCE_ERROR";

export const UPDATE_DYNAMIC_USAGES_SUCCESS = "UPDATE_DYNAMIC_USAGES_SUCCESS";

// PORTFOLIO SCENARIO
export const GET_PORTFOLIO_USAGE_REQUEST = "GET_PORTFOLIO_USAGE_REQUEST";
export const GET_PORTFOLIO_USAGE_SUCCESS = "GET_PORTFOLIO_USAGE_SUCCESS";
export const GET_PORTFOLIO_USAGE_ERROR = "GET_PORTFOLIO_USAGE_ERROR";

// MANUAL PORTFOLIO LEVELING
export const ADD_ID = "ADD_ID";
export const RESET_IDS = "RESET_IDS";

// ATTRIBUTES

export const CREATE_ATTRIBUTE_REQUEST = "CREATE_ATTRIBUTE_REQUEST";
export const CREATE_ATTRIBUTE_SUCCESS = "CREATE_ATTRIBUTE_SUCCESS";
export const CREATE_ATTRIBUTE_ERROR = "CREATE_ATTRIBUTE_ERROR";

export const GET_ATTRIBUTES_REQUEST = "GET_ATTRIBUTES_REQUEST";
export const GET_ATTRIBUTES_SUCCESS = "GET_ATTRIBUTES_SUCCESS";
export const GET_ATTRIBUTES_ERROR = "GET_ATTRIBUTES_ERROR";

export const GET_ATTRIBUTE = "GET_ATTRIBUTE";
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
export const DELETE_ATTRIBUTES = "DELETE_ATTRIBUTES";
export const UPDATE_ATTRIBUTE_REQUEST = "UPDATE_ATTRIBUTE_REQUEST";
export const UPDATE_ATTRIBUTE_SUCCESS = "UPDATE_ATTRIBUTE_SUCCESS";
export const UPDATE_ATTRIBUTE_ERROR = "UPDATE_ATTRIBUTE_ERROR";

export const GET_CAPEX_LANDINGS_REQUEST = "GET_CAPEX_LANDINGS_REQUEST";
export const GET_CAPEX_LANDINGS_SUCCESS = "GET_CAPEX_LANDINGS_SUCCESS";
export const GET_CAPEX_LANDINGS_ERROR = "GET_CAPEX_LANDINGS_ERROR";

// QUANTITIES
export const GET_QUANTITIES = "GET_QUANTITIES";

export const GET_QUANTITIES_REQUEST = "GET_QUANTITIES_REQUEST";
export const GET_QUANTITIES_SUCCESS = "GET_QUANTITIES_SUCCESS";
export const GET_QUANTITIES_ERROR = "GET_QUANTITIES_ERROR";

export const GET_QUANTITY = "GET_QUANTITY";
export const DELETE_QUANTITY = "DELETE_QUANTITY";
export const DELETE_QUANTITIES = "DELETE_QUANTITIES";

export const UPDATE_QUANTITY_REQUEST = "UPDATE_QUANTITY_REQUEST";
export const UPDATE_QUANTITY_SUCCESS = "UPDATE_QUANTITY_SUCCESS";
export const UPDATE_QUANTITY_ERROR = "UPDATE_QUANTITY_ERROR";

// CALENDARS
export const CREATE_CALENDAR_REQUEST = "CREATE_CALENDAR_REQUEST";
export const CREATE_CALENDAR_SUCCESS = "CREATE_CALENDAR_SUCCESS";
export const CREATE_CALENDAR_ERROR = "CREATE_CALENDAR_ERROR";

export const GET_PORTFOLIO_CALENDARS_REQUEST =
  "GET_PORTFOLIO_CALENDARS_REQUEST";
export const GET_PORTFOLIO_CALENDARS_SUCCESS =
  "GET_PORTFOLIO_CALENDARS_SUCCESS";
export const GET_PORTFOLIO_CALENDARS_ERROR = "GET_PORTFOLIO_CALENDARS_ERROR";

export const GET_WORKABLE_DAYSNAME_PORTFOLIO_CALENDARS_REQUEST =
  "GET_WORKABLE_DAYSNAME_PORTFOLIO_CALENDARS_REQUEST";
export const GET_WORKABLE_DAYSNAME_PORTFOLIO_CALENDARS_SUCCESS =
  "GET_WORKABLE_DAYSNAME_PORTFOLIO_CALENDARS_SUCCESS";
export const GET_WORKABLE_DAYSNAME_PORTFOLIO_CALENDARS_ERROR =
  "GET_WORKABLE_DAYSNAME_PORTFOLIO_CALENDARS_ERROR";

export const GET_WORKABLE_DAYSNAME_RESOURCES_CALENDARS_REQUEST =
  "GET_WORKABLE_DAYSNAME_RESOURCES_CALENDARS_REQUEST";
export const GET_WORKABLE_DAYSNAME_RESOURCES_CALENDARS_SUCCESS =
  "GET_WORKABLE_DAYSNAME_RESOURCES_CALENDARS_SUCCESS";
export const GET_WORKABLE_DAYSNAME_RESOURCES_CALENDARS_ERROR =
  "GET_WORKABLE_DAYSNAME_RESOURCES_CALENDARS_ERROR";

export const UPDATE_PORTFOLIO_CALENDAR_REQUEST =
  "UPDATE_PORTFOLIO_CALENDAR_REQUEST";
export const UPDATE_PORTFOLIO_CALENDAR_SUCCESS =
  "UPDATE_PORTFOLIO_CALENDAR_SUCCESS";
export const UPDATE_PORTFOLIO_CALENDAR_ERROR =
  "UPDATE_PORTFOLIO_CALENDAR_ERROR";

// USAGES

// USER
export const GET_CURRENT_USER_REQUEST = "GET_CURRENT_USER_REQUEST";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_ERROR = "GET_CURRENT_USER_ERROR";
export const GET_USER_PROJECTS_PERMISSIONS = "GET_USER_PROJECTS_PERMISSIONS";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";

export const GENERATE_PASSWORD_REQUEST = "GENERATE_PASSWORD_REQUEST";
export const GENERATE_PASSWORD_SUCCESS = "GENERATE_PASSWORD_SUCCESS";
export const GENERATE_PASSWORD_ERROR = "GENERATE_PASSWORD_ERROR";

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_ERROR = "USER_CREATE_ERROR";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export const GET_USER_ROLES_REQUEST = "GET_USER_ROLES_REQUEST";
export const GET_USER_ROLES_SUCCESS = "GET_USER_ROLES_SUCCESS";
export const GET_USER_ROLES_ERROR = "GET_USER_ROLES_ERROR";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_ERROR = "USER_DELETE_ERROR";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR";

// TENANT
export const TENANT_CREATE_REQUEST = "TENANT_CREATE_REQUEST";
export const TENANT_CREATE_SUCCESS = "TENANT_CREATE_SUCCESS";
export const TENANT_CREATE_ERROR = "TENANT_CREATE_ERROR";

export const GET_TENANTS_REQUEST = "GET_TENANTS_REQUEST";
export const GET_TENANTS_SUCCESS = "GET_TENANTS_SUCCESS";
export const GET_TENANTS_ERROR = "GET_TENANTS_ERROR";

export const TENANT_DELETE_REQUEST = "TENANT_DELETE_REQUEST";
export const TENANT_DELETE_SUCCESS = "TENANT_DELETE_SUCCESS";
export const TENANT_DELETE_ERROR = "TENANT_DELETE_ERROR";

export const TENANT_UPDATE_REQUEST = "TENANT_UPDATE_REQUEST";
export const TENANT_UPDATE_SUCCESS = "TENANT_UPDATE_SUCCESS";
export const TENANT_UPDATE_ERROR = "TENANT_UPDATE_ERROR";

// GENERIC METRICS
export const UPDATE_G_M_REQUEST = "UPDATE_G_M_REQUEST";
export const UPDATE_G_M_SUCCESS = "UPDATE_G_M_SUCCESS";
export const UPDATE_G_M_ERROR = "UPDATE_G_M_ERROR";

export const GET_G_M_LIST_REQUEST = "GET_G_M_LIST_REQUEST";
export const GET_G_M_LIST_SUCCESS = "GET_G_M_LIST_SUCCESS";
export const GET_G_M_LIST_ERROR = "GET_G_M_LIST_ERROR";

export const UPDATE_G_M_LIST_REQUEST = "UPDATE_G_M_LIST_REQUEST";
export const UPDATE_G_M_LIST_SUCCESS = "UPDATE_G_M_LIST_SUCCESS";
export const UPDATE_G_M_LIST_ERROR = "UPDATE_G_M_LIST_ERROR";

// LOGIN
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

// LOGOUT
export const LOGOUT = "LOGOUT";

// standard types
export const CLEAR_BACKEND_ERROR_MESSAGE = "CLEAR_BACKEND_ERROR_MESSAGE";

// standart
export const GET_STANDARD_LIBRARIES_REQUEST = "GET_STANDARD_LIBRARIES_REQUEST";
export const GET_STANDARD_LIBRARIES_SUCCESS = "GET_STANDARD_LIBRARIES_SUCCESS";
export const GET_STANDARD_LIBRARIES_ERROR = "GET_STANDARD_LIBRARIES_ERROR";
export const GET_STANDARD_BLOCKS_REQUEST = "GET_STANDARD_BLOCKS_REQUEST";
export const GET_STANDARD_BLOCKS_SUCCESS = "GET_STANDARD_BLOCKS_SUCCESS";
export const GET_STANDARD_BLOCKS_ERROR = "GET_STANDARD_BLOCKS_ERROR";
export const ADD_STANDARD_LIBRARY = "ADD_STANDARD_LIBRARY";
export const UPDATE_STANDARD_LIBRARY = "UPDATE_STANDARD_LIBRARY";
export const DELETE_STANDARD_LIBRARY = "DELETE_STANDARD_LIBRARY";
export const ADD_STANDARD_BLOCK = "ADD_STANDARD_BLOCK";
export const UPDATE_STANDARD_BLOCK = "UPDATE_STANDARD_BLOCK";
export const DELETE_STANDARD_BLOCK = "DELETE_STANDARD_BLOCK";

// tag
export const TAG_CREATE_REQUEST = "TAG_CREATE_REQUEST";
export const TAG_CREATE_SUCCESS = "TAG_CREATE_SUCCESS";
export const TAG_CREATE_ERROR = "TAG_CREATE_ERROR";
export const GET_TAGS_REQUEST = "GET_TAGS_REQUEST";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_ERROR = "GET_TAGS_ERROR";
export const TAG_DELETE_REQUEST = "TAG_DELETE_REQUEST";
export const TAG_DELETE_SUCCESS = "TAG_DELETE_SUCCESS";
export const TAG_DELETE_ERROR = "TAG_DELETE_ERROR";
export const TAG_UPDATE_REQUEST = "TAG_UPDATE_REQUEST";
export const TAG_UPDATE_SUCCESS = "TAG_UPDATE_SUCCESS";
export const TAG_UPDATE_ERROR = "TAG_UPDATE_ERROR";
export const GET_TAGS_BY_STRUCTURE_REQUEST = "GET_TAGS_BY_STRUCTURE_REQUEST";
export const GET_TAGS_BY_STRUCTURE_SUCCESS = "GET_TAGS_BY_STRUCTURE_SUCCESS";
export const GET_TAGS_BY_STRUCTURE_ERROR = "GET_TAGS_BY_STRUCTURE_ERROR";
export const CLEAR_TAGS_FOR_STRUCTURE = "CLEAR_TAGS_FOR_STRUCTURE";

// utils
export const UPDATE_GANTT_PERIOD = "UPDATE_GANTT_PERIOD";
export const UPDATE_GANTT_SIZE = "UPDATE_GANTT_SIZE";
export const UPDATE_PANELS_HEIGHT = "UPDATE_PANELS_HEIGHT";
export const SELECT_REGULARS = "SELECT_REGULARS";
export const SELECT_TASKS = "SELECT_TASKS";
export const RESIZE_PIVOT_CHART = "RESIZE_PIVOT_CHART";
export const UPDATE_SELECTED_TAB_INDEX = "UPDATE_SELECTED_TAB_INDEX";
export const SELECT_ROW = "SELECT_ROW";
export const SET_UPDATING_STATUS = "SET_UPDATING_STATUS";
export const ALLOW_TO_RENDER = "ALLOW_TO_RENDER";
export const DISABLE_SAVING = "DISABLE_SAVING";
export const SET_TASK_TO_ASSIGN = "SET_TASK_TO_ASSIGN";
export const SET_ITEM_TO_EDIT = "SET_ITEM_TO_EDIT";
export const SET_MY_SCOPES_VIEW = "SET_MY_SCOPES_VIEW";
export const SHOW_MAPPING_GRID = "SHOW_MAPPING_GRID";
export const HIDE_MAPPING_GRID = "HIDE_MAPPING_GRID";
export const SET_WORKLOAD_FILTERS = "SET_WORKLOAD_FILTERS";
export const ADD_ITEM_PENDING = "ADD_ITEM_PENDING";
export const REMOVE_ITEM_PENDING = "REMOVE_ITEM_PENDING";
export const CLEAR_ITEMS_PENDING = "CLEAR_ITEMS_PENDING";
export const SET_SELECTED_ACTIVITY = "SET_SELECTED_ACTIVITY";
export const SET_REGULAR_TO_ASSIGN = "SET_REGULAR_TO_ASSIGN";
export const SET_CHART_FILTERING = "SET_CHART_FILTERING";
export const SET_WORK_DAYS_CALENDAR = "SET_WORK_DAYS_CALENDAR";
export const RECREATE_SCHEDULER_INSTANCE = "RECREATE_SCHEDULER_INSTANCE";
export const RESET_GANTT_CUSTOM_COLUMNS = "RESET_GANTT_CUSTOM_COLUMNS";
export const SET_GANTT_CUSTOM_COLUMNS = "SET_GANTT_CUSTOM_COLUMNS";

// visual info
export const GET_VISUAL_INFO_BY_STRUCTURE_REQUEST =
  "GET_VISUAL_INFO_BY_STRUCTURE_REQUEST";
export const GET_VISUAL_INFO_BY_STRUCTURE_SUCCESS =
  "GET_VISUAL_INFO_BY_STRUCTURE_SUCCESS";
export const GET_VISUAL_INFO_BY_STRUCTURE_ERROR =
  "GET_VISUAL_INFO_BY_STRUCTURE_ERROR";
export const CREATE_VISUAL_INFO_REQUEST = "CREATE_VISUAL_INFO_REQUEST";
export const CREATE_VISUAL_INFO_SUCCESS = "CREATE_VISUAL_INFO_SUCCESS";
export const CREATE_VISUAL_INFO_ERROR = "CREATE_VISUAL_INFO_ERROR";
export const UPDATE_VISUAL_INFO_REQUEST = "UPDATE_VISUAL_INFO_REQUEST";
export const UPDATE_VISUAL_INFO_SUCCESS = "UPDATE_VISUAL_INFO_SUCCESS";
export const UPDATE_VISUAL_INFO_ERROR = "UPDATE_VISUAL_INFO_ERROR";
export const UPDATE_ALL_VISUAL_INFO_REQUEST = "UPDATE_ALL_VISUAL_INFO_REQUEST";
export const UPDATE_ALL_VISUAL_INFO_SUCCESS = "UPDATE_ALL_VISUAL_INFO_SUCCESS";
export const UPDATE_ALL_VISUAL_INFO_ERROR = "UPDATE_ALL_VISUAL_INFO_ERROR";
export const DELETE_VISUAL_INFO_REQUEST = "DELETE_VISUAL_INFO_REQUEST";
export const DELETE_VISUAL_INFO_SUCCESS = "DELETE_VISUAL_INFO_SUCCESS";
export const DELETE_VISUAL_INFO_ERROR = "DELETE_VISUAL_INFO_ERROR";
export const CLEAR_VISUAL_INFO = "CLEAR_VISUAL_INFO";

// visual planning
export const GET_VISUAL_PLANNING_REQUEST = "GET_VISUAL_PLANNING_REQUEST";
export const GET_VISUAL_PLANNING_SUCCESS = "GET_VISUAL_PLANNING_SUCCESS";
export const GET_VISUAL_PLANNING_ERROR = "GET_VISUAL_PLANNING_ERROR";
export const CREATE_VISUAL_PLANNING_REQUEST = "CREATE_VISUAL_PLANNING_REQUEST";
export const CREATE_VISUAL_PLANNING_SUCCESS = "CREATE_VISUAL_PLANNING_SUCCESS";
export const CREATE_VISUAL_PLANNING_ERROR = "CREATE_VISUAL_PLANNING_ERROR";
export const UPDATE_VISUAL_PLANNING_REQUEST = "UPDATE_VISUAL_PLANNING_REQUEST";
export const UPDATE_VISUAL_PLANNING_SUCCESS = "UPDATE_VISUAL_PLANNING_SUCCESS";
export const UPDATE_VISUAL_PLANNING_ERROR = "UPDATE_VISUAL_PLANNING_ERROR";
export const DELETE_VISUAL_PLANNING_REQUEST = "DELETE_VISUAL_PLANNING_REQUEST";
export const DELETE_VISUAL_PLANNING_SUCCESS = "DELETE_VISUAL_PLANNING_SUCCESS";
export const DELETE_VISUAL_PLANNING_ERROR = "DELETE_VISUAL_PLANNING_ERROR";

// project movie
export const SET_PROJECT_MOVIE_SETTINGS = "SET_PROJECT_MOVIE_SETTINGS";
export const SET_MOVIE_FRAMES = "SET_MOVIE_FRAMES";
export const SET_CURRENT_MOVIE_INDEX = "SET_CURRENT_MOVIE_INDEX";
export const CLEAR_PROJECT_MOVIE = "CLEAR_PROJECT_MOVIE";

// scheduler
export const GET_RESOURCE_TASKS_REQUEST = "GET_RESOURCE_TASKS_REQUEST";
export const GET_RESOURCE_TASKS_SUCCESS = "GET_RESOURCE_TASKS_SUCCESS";
export const GET_RESOURCE_TASKS_ERROR = "GET_RESOURCE_TASKS_ERROR";
export const CREATE_RESOURCE_TASK_REQUEST = "CREATE_RESOURCE_TASK_REQUEST";
export const CREATE_RESOURCE_TASK_SUCCESS = "CREATE_RESOURCE_TASK_SUCCESS";
export const CREATE_RESOURCE_TASK_ERROR = "CREATE_RESOURCE_TASK_ERROR";
export const ASSIGN_RESOURCE_TASK_REQUEST = "ASSIGN_RESOURCE_TASK_REQUEST";
export const ASSIGN_RESOURCE_TASK_SUCCESS = "ASSIGN_RESOURCE_TASK_SUCCESS";
export const ASSIGN_RESOURCE_TASK_ERROR = "ASSIGN_RESOURCE_TASK_ERROR";
export const UPDATE_RESOURCE_TASK_REQUEST = "UPDATE_RESOURCE_TASK_REQUEST";
export const UPDATE_RESOURCE_TASK_SUCCESS = "UPDATE_RESOURCE_TASK_SUCCESS";
export const UPDATE_RESOURCE_TASK_ERROR = "UPDATE_RESOURCE_TASK_ERROR";
export const DELETE_RESOURCE_TASK_REQUEST = "DELETE_RESOURCE_TASK_REQUEST";
export const DELETE_RESOURCE_TASK_SUCCESS = "DELETE_RESOURCE_TASK_SUCCESS";
export const DELETE_RESOURCE_TASK_ERROR = "DELETE_RESOURCE_TASK_ERROR";
export const CHECK_IS_MANAGER_SUCCESS = "CHECK_IS_MANAGER_SUCCESS";
export const CHECK_IS_MANAGER_ERROR = "CHECK_IS_MANAGER_ERROR";
export const UPDATE_ACTIVE = "UPDATE_ACTIVE";
export const SET_ALL_AS_ACTIVE = "SET_ALL_AS_ACTIVE";
export const SET_ALL_AS_INACTIVE = "SET_ALL_AS_INACTIVE";
export const GET_RESOURCE_TASKS_BY_SCENARIO_ID_REQUEST =
  "GET_RESOURCE_TASKS_BY_SCENARIO_ID_REQUEST";
export const GET_RESOURCE_TASKS_BY_SCENARIO_ID_SUCCESS =
  "GET_RESOURCE_TASKS_BY_SCENARIO_ID_SUCCESS";
export const GET_RESOURCE_TASKS_BY_SCENARIO_ID_ERROR =
  "GET_RESOURCE_TASKS_BY_SCENARIO_ID_ERROR";

export const GET_RESOURCE_TASKS_DASHBOARD_REQUEST =
  "GET_RESOURCE_TASKS_DASHBOARD_REQUEST";
export const GET_RESOURCE_TASKS_DASHBOARD_SUCCESS =
  "GET_RESOURCE_TASKS_DASHBOARD_SUCCESS";
export const GET_RESOURCE_TASKS_DASHBOARD_ERROR =
  "GET_RESOURCE_TASKS_DASHBOARD_ERROR";

export const GET_RESOURCE_TASKS_CUSTOM_COLUMNS_REQUEST =
  "GET_RESOURCE_TASKS_CUSTOM_COLUMNS_REQUEST";
export const GET_RESOURCE_TASKS_CUSTOM_COLUMNS_SUCCESS =
  "GET_RESOURCE_TASKS_CUSTOM_COLUMNS_SUCCESS";
export const GET_RESOURCE_TASKS_CUSTOM_COLUMNS_ERROR =
  "GET_RESOURCE_TASKS_CUSTOM_COLUMNS_ERROR";

export const ADD_RT_CUSTOM_COLUMNS = "ADD_RT_CUSTOM_COLUMNS";

export const GET_RESOURCE_TASKS_CUSTOM_COLUMN_NAMES_REQUEST =
  "GET_RESOURCE_TASKS_CUSTOM_COLUMN_NAMES_REQUEST";
export const GET_RESOURCE_TASKS_CUSTOM_COLUMN_NAMES_SUCCESS =
  "GET_RESOURCE_TASKS_CUSTOM_COLUMN_NAMES_SUCCESS";
export const GET_RESOURCE_TASKS_CUSTOM_COLUMN_NAMES_ERROR =
  "GET_RESOURCE_TASKS_CUSTOM_COLUMN_NAMES_ERROR";

export const TOP_DOWN_RESOURCE_TASK_UPDATE = "TOP_DOWN_RESOURCE_TASK_UPDATE";
export const UPDATE_USER_TASK = "UPDATE_USER_TASK";
export const UPDATE_USER_TASK_STATUS_AND_PROGRESS =
  "UPDATE_USER_TASK_STATUS_AND_PROGRESS";

// WORKLOAD
export const GET_TEAMS_WORKLOAD_REQUEST = "GET_TEAMS_WORKLOAD_REQUEST";
export const GET_TEAMS_WORKLOAD_SUCCESS = "GET_TEAMS_WORKLOAD_SUCCESS";
export const GET_TEAMS_WORKLOAD_ERROR = "GET_TEAMS_WORKLOAD_ERROR";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";

export const UPDATE_TEAMS_WORKLOAD = "UPDATE_TEAMS_WORKLOAD";

export const UPDATE_WORKLOAD_ON_PROJECTBUCKETS_REQUEST =
  "UPDATE_WORKLOAD_ON_PROJECTBUCKETS_REQUEST";
export const UPDATE_WORKLOAD_ON_PROJECTBUCKETS_SUCCESS =
  "UPDATE_WORKLOAD_ON_PROJECTBUCKETS_SUCCESS";
export const UPDATE_WORKLOAD_ON_PROJECTBUCKETS_ERROR =
  "UPDATE_WORKLOAD_ON_PROJECTBUCKETS_ERROR";
export const COPY_AT_COMPLETION = "COPY_AT_COMPLETION";
export const COPY_AT_COMPLETION_REQUEST = "COPY_AT_COMPLETION_REQUEST";
export const COPY_AT_COMPLETION_SUCCESS = "COPY_AT_COMPLETION_SUCCESS";
export const COPY_AT_COMPLETION_ERROR = "COPY_AT_COMPLETION_ERROR";

// Portfolio actions
export const UPDATE_REVIEW_DATE_REQUEST = "UPDATE_REVIEW_DATE_REQUEST";
export const UPDATE_REVIEW_DATE_SUCCESS = "UPDATE_REVIEW_DATE_SUCCESS";
export const UPDATE_REVIEW_DATE_ERROR = "UPDATE_REVIEW_DATE_ERROR";

export const UPDATE_WORKLOAD_EDITION_REQUEST =
  "UPDATE_WORKLOAD_EDITION_REQUEST";
export const UPDATE_WORKLOAD_EDITION_SUCCESS =
  "UPDATE_WORKLOAD_EDITION_SUCCESS";
export const UPDATE_WORKLOAD_EDITION_ERROR = "UPDATE_WORKLOAD_EDITION_ERROR";

export const GET_DETAILS_REQUEST = "GET_DETAILS_REQUEST";
export const GET_DETAILS_SUCCESS = "GET_DETAILS_SUCCESS";
export const GET_DETAILS_ERROR = "GET_DETAILS_ERROR";

export const GET_PORTFOLIO_REQUEST = "GET_PORTFOLIO_REQUEST";
export const GET_PORTFOLIO_SUCCESS = "GET_PORTFOLIO_SUCCESS";
export const GET_PORTFOLIO_ERROR = "GET_PORTFOLIO_ERROR";
export const GET_PORTFOLIO_OPTIONS = "GET_PORTFOLIO_OPTIONS";
export const ADD_PORTFOLIO_OPTION = "ADD_PORTFOLIO_OPTION";

// Resource spectrum
export const GET_RESOURCE_SPECTRUM_REQUEST = "GET_RESOURCE_SPECTRUM_REQUEST";
export const GET_RESOURCE_SPECTRUM_SUCCESS = "GET_RESOURCE_SPECTRUM_SUCCESS";
export const GET_RESOURCE_SPECTRUM_ERROR = "GET_RESOURCE_SPECTRUM_ERROR";
export const CREATE_RESOURCE_SPECTRUM_REQUEST =
  "CREATE_RESOURCE_SPECTRUM_REQUEST";
export const CREATE_RESOURCE_SPECTRUM_SUCCESS =
  "CREATE_RESOURCE_SPECTRUM_SUCCESS";
export const CREATE_RESOURCE_SPECTRUM_ERROR = "CREATE_RESOURCE_SPECTRUM_ERROR";
export const UPDATE_RESOURCE_SPECTRUM_REQUEST =
  "UPDATE_RESOURCE_SPECTRUM_REQUEST";
export const UPDATE_RESOURCE_SPECTRUM_SUCCESS =
  "UPDATE_RESOURCE_SPECTRUM_SUCCESS";
export const UPDATE_RESOURCE_SPECTRUM_ERROR = "UPDATE_RESOURCE_SPECTRUM_ERROR";
export const DELETE_RESOURCE_SPECTRUM_REQUEST =
  "DELETE_RESOURCE_SPECTRUM_REQUEST";
export const DELETE_RESOURCE_SPECTRUM_SUCCESS =
  "DELETE_RESOURCE_SPECTRUM_SUCCESS";
export const DELETE_RESOURCE_SPECTRUM_ERROR = "DELETE_RESOURCE_SPECTRUM_ERROR";
export const GET_PORTFOLIO_SPECTRUM_REQUEST = "GET_PORTFOLIO_SPECTRUM_REQUEST";
export const GET_PORTFOLIO_SPECTRUM_SUCCESS = "GET_PORTFOLIO_SPECTRUM_SUCCESS";
export const GET_PORTFOLIO_SPECTRUM_ERROR = "GET_PORTFOLIO_SPECTRUM_ERROR";

// AI
export const AI_TEST_REQUEST = "AI_TEST_REQUEST";
export const AI_TEST_SUCCESS = "AI_TEST_SUCCESS";
export const AI_TEST_ERROR = "AI_TEST_ERROR";
export const AI_TEST_CLEAR = "AI_TEST_CLEAR";

// Timeline
export const GET_FULL_TIMELINE_REQUEST = "GET_FULL_TIMELINE_REQUEST";
export const GET_FULL_TIMELINE_SUCCESS = "GET_FULL_TIMELINE_SUCCESS";
export const GET_FULL_TIMELINE_ERROR = "GET_FULL_TIMELINE_ERROR";
export const GET_FULL_DETAILS_REQUEST = "GET_FULL_DETAILS_REQUEST";
export const GET_FULL_DETAILS_SUCCESS = "GET_FULL_DETAILS_SUCCESS";
export const GET_FULL_DETAILS_ERROR = "GET_FULL_DETAILS_ERROR";
export const GET_FULL_USAGES_REQUEST = "GET_FULL_USAGES_REQUEST";
export const GET_FULL_USAGES_SUCCESS = "GET_FULL_USAGES_SUCCESS";
export const GET_FULL_USAGES_ERROR = "GET_FULL_USAGES_ERROR";
export const GET_CUSTOM_TIMELINE_REQUEST = "GET_CUSTOM_TIMELINE_REQUEST";
export const GET_CUSTOM_TIMELINE_SUCCESS = "GET_CUSTOM_TIMELINE_SUCCESS";
export const GET_CUSTOM_TIMELINE_ERROR = "GET_CUSTOM_TIMELINE_ERROR";
export const GET_CUSTOM_DETAILS_REQUEST = "GET_CUSTOM_DETAILS_REQUEST";
export const GET_CUSTOM_DETAILS_SUCCESS = "GET_CUSTOM_DETAILS_SUCCESS";
export const GET_CUSTOM_DETAILS_ERROR = "GET_CUSTOM_DETAILS_ERROR";
export const GET_CUSTOM_USAGES_REQUEST = "GET_CUSTOM_USAGES_REQUEST";
export const GET_CUSTOM_USAGES_SUCCESS = "GET_CUSTOM_USAGES_SUCCESS";
export const GET_CUSTOM_USAGES_ERROR = "GET_CUSTOM_USAGES_ERROR";
export const GET_CUSTOM_SUPPLY_REQUEST = "GET_CUSTOM_SUPPLY_REQUEST";
export const GET_CUSTOM_SUPPLY_SUCCESS = "GET_CUSTOM_SUPPLY_SUCCESS";
export const GET_CUSTOM_SUPPLY_ERROR = "GET_CUSTOM_SUPPLY_ERROR";
export const GET_CUSTOM_SUPPLY_BY_PROJECTS_REQUEST =
  "GET_CUSTOM_SUPPLY_BY_PROJECTS_REQUEST";
export const GET_CUSTOM_SUPPLY_BY_PROJECTS_SUCCESS =
  "GET_CUSTOM_SUPPLY_BY_PROJECTS_SUCCESS";
export const GET_CUSTOM_SUPPLY_BY_PROJECTS_ERROR =
  "GET_CUSTOM_SUPPLY_BY_PROJECTS_ERROR";

// font
export const CREATE_FONT_REQUEST = "CREATE_FONT_REQUEST";
export const CREATE_FONT_SUCCESS = "CREATE_FONT_SUCCESS";
export const CREATE_FONT_ERROR = "CREATE_FONT_ERROR";

export const DELETE_FONT_REQUEST = "DELETE_FONT_REQUEST";
export const DELETE_FONT_SUCCESS = "DELETE_FONT_SUCCESS";
export const DELETE_FONT_ERROR = "DELETE_FONT_ERROR";

export const GET_FONT_REQUEST = "GET_FONT_REQUEST";
export const GET_FONT_SUCCESS = "GET_FONT_SUCCESS";
export const GET_FONT_ERROR = "GET_FONT_ERROR";

export const UPDATE_FONT_REQUEST = "UPDATE_FONT_REQUEST";
export const UPDATE_FONT_SUCCESS = "UPDATE_FONT_SUCCESS";
export const UPDATE_FONT_ERROR = "UPDATE_FONT_ERROR";

// STANDARD API
export const IMPORT_EXTERNAL_DATA_BY_CSV_REQUEST =
  "IMPORT_EXTERNAL_DATA_BY_CSV_REQUEST";
export const IMPORT_EXTERNAL_DATA_BY_CSV_SUCCESS =
  "IMPORT_EXTERNAL_DATA_BY_CSV_SUCCESS";
export const IMPORT_EXTERNAL_DATA_BY_CSV_ERROR =
  "IMPORT_EXTERNAL_DATA_BY_CSV_ERROR";

// PORTFOLIO SCENARIOS
export const ADD_PORTFOLIO_SCENARIO = "ADD_PORTFOLIO_SCENARIO";
export const GET_PORTFOLIO_SCENARIOS_REQUEST =
  "GET_PORTFOLIO_SCENARIOS_REQUEST";
export const GET_PORTFOLIO_SCENARIOS_SUCCESS =
  "GET_PORTFOLIO_SCENARIOS_SUCCESS";
export const GET_PORTFOLIO_SCENARIOS_ERROR = "GET_PORTFOLIO_SCENARIOS_ERROR";

export const DELETE_PORTFOLIO_SCENARIO_REQUEST =
  "DELETE_PORTFOLIO_SCENARIO_REQUEST";
export const DELETE_PORTFOLIO_SCENARIO_SUCCESS =
  "DELETE_PORTFOLIO_SCENARIO_SUCCESS";
export const DELETE_PORTFOLIO_SCENARIO_ERROR =
  "DELETE_PORTFOLIO_SCENARIO_ERROR";

export const UPDATE_PORTFOLIO_SCENARIO_REQUEST =
  "UPDATE_PORTFOLIO_SCENARIO_REQUEST";
export const UPDATE_PORTFOLIO_SCENARIO_SUCCESS =
  "UPDATE_PORTFOLIO_SCENARIO_SUCCESS";
export const UPDATE_PORTFOLIO_SCENARIO_ERROR =
  "UPDATE_PORTFOLIO_SCENARIO_ERROR";

export const APPROVE_PORTFOLIO_SCENARIO = "APPROVE_PORTFOLIO_SCENARIO";
export const REJECT_PORTFOLIO_SCENARIO = "REJECT_PORTFOLIO_SCENARIO";

export const GET_PORTFOLIO_SCENARIO_NAME = "GET_PORTFOLIO_SCENARIO_NAME";
export const SET_PORTFOLIO_SCENARIO_AS_SIMULATED =
  "SET_PORTFOLIO_SCENARIO_AS_SIMULATED";

// Column permissions
export const GET_COLUMN_PERMISSION_REQUEST = "GET_COLUMN_PERMISSION_REQUEST";
export const GET_COLUMN_PERMISSION_SUCCESS = "GET_COLUMN_PERMISSION_SUCCESS";
export const GET_COLUMN_PERMISSION_ERROR = "GET_COLUMN_PERMISSION_ERROR";

export const UPDATE_COLUMN_PERMISSION_REQUEST =
  "UPDATE_COLUMN_PERMISSION_REQUEST";
export const UPDATE_COLUMN_PERMISSION_SUCCESS =
  "UPDATE_COLUMN_PERMISSION_SUCCESS";
export const UPDATE_COLUMN_PERMISSION_ERROR = "UPDATE_COLUMN_PERMISSION_ERROR";

// Settings update
export const UPDATE_DETAILS_REQUEST = "UPDATE_DETAILS_REQUEST";
export const UPDATE_DETAILS_SUCCESS = "UPDATE_DETAILS_SUCCESS";
export const UPDATE_DETAILS_ERROR = "UPDATE_DETAILS_ERROR";
