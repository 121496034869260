/**
 * Determines if any of the given values is falsy.
 *
 * @function
 * @name hasFalsyValue
 * @param {...*} values - Any number of arguments to check for falsy values.
 * @returns {boolean} Returns `true` if at least one value is falsy, otherwise `false`.
 * @example
 * hasFalsyValue(null, "test", 123); // true
 * hasFalsyValue("Hello", "World");  // false
 */
export const hasFalsyValue = (...values) => {
  return values.some((value) => !value);
};

/**
 * Computes a result based on the given conditions.
 * If all conditions are truthy, it will execute the compute function.
 * If any of the conditions is falsy, it will return the default value.
 *
 * @function
 * @name conditionalCompute
 * @param {Array} conditions - Array of conditions to check.
 * @param {Function} computeFn - The function to execute if all conditions are truthy.
 * @param {*} defaultValue - The default value to return if any condition is falsy.
 * @returns {*} Returns the result of `computeFn` if all conditions are truthy, otherwise returns `defaultValue`.
 * @example
 * const compute = () => "Hello World";
 * conditionalCompute([true, "test"], compute, "Default"); // "Hello World"
 * conditionalCompute([false, "test"], compute, "Default"); // "Default"
 */
export const conditionalCompute = (conditions, computeFn, defaultValue) => {
  if (hasFalsyValue(...conditions)) return defaultValue;
  return computeFn();
};

/**
 * Checks if both objects are empty.
 * @param {Object} obj1 - The first object to check.
 * @param {Object} obj2 - The second object to check.
 * @returns {boolean} - True if both objects are empty, false otherwise.
 */
export function bothObjectsAreEmpty(obj1, obj2) {
  const keysOfObj1 = Object.keys(obj1);

  // exit condition to avoid useless declaration
  if (keysOfObj1.length) return false;

  const keysOfObj2 = Object.keys(obj2);

  return keysOfObj1.length === 0 && keysOfObj2.length === 0;
}

/**
 * Creates a timer function that limits the frequency of invoking the provided callback.
 * @param {Function} callback - The callback function to be invoked.
 * @param {number} time - The minimum time interval (in milliseconds) between invocations of the callback.
 * @returns {Function} - A timer function that limits the frequency of invoking the callback.
 */
export function createTimer(callback, time) {
  let canApplyCallback = true;
  return (...args) => {
    if (canApplyCallback) {
      canApplyCallback = false;

      setTimeout(() => (canApplyCallback = true), time);

      Reflect.apply(callback, null, args);
    }
  };
}

/**
 * Creates a deep copy of a JavaScript object.
 *
 * @param {Object} object - The object to be copied.
 * @returns {Object} - A deep copy of the input object.
 */
export const copyObjectInDepth = (object) => JSON.parse(JSON.stringify(object));

/**
 * Removes a DOM element from the document if it already exists.
 *
 * @param {string} domElementId - The ID of the DOM element to remove.
 */
export const removeElementByIdIfAlreadyExists = (domElementId) => {
  const elementToRemove = document.getElementById(domElementId);

  // If the element exists, remove it from the DOM.
  if (elementToRemove) elementToRemove.remove();
};

/**
 * Checks if an array includes a specific element or if the array is empty.
 *
 * @param {Array} array - The array to be checked.
 * @param {*} element - The element to check for in the array.
 * @returns {boolean} - Returns true if the array is empty or includes the specified element, otherwise returns false.
 */
export const arrayIncludesOrIsEmpty = (array, element) =>
  array.length === 0 || array.includes(element);

/**
 * Increments a field in the target object with the corresponding value from the source object,
 * or initializes the field in the target object if it doesn't exist.
 *
 * @param {Object} source - The source object containing the value to be incremented.
 * @param {Object} target - The target object where the value will be incremented or initialized.
 * @param {string} sharedFieldName - The name of the field shared between the source and target objects.
 */
export const incrementOrInitialize = (source, target, sharedFieldName) => {
  if (sharedFieldName in target)
    target[sharedFieldName] += source[sharedFieldName];
  else target[sharedFieldName] = source[sharedFieldName];
};

export const incrementOrInitializeObject = (
  target,
  targetFieldName,
  valueToBeAdded
) => {
  if (targetFieldName in target) target[targetFieldName] += valueToBeAdded;
  else target[targetFieldName] = valueToBeAdded;
};

/**
 * Initializes a field in the object with a default value if it doesn't already exist.
 *
 * @param {Object} object - The object to be initialized.
 * @param {string} field - The name of the field to be initialized.
 * @param {*} [defaultValue={}] - The default value to be assigned to the field if it doesn't exist. Default is an empty object.
 */
export const initializeObjectIfDoesntExist = (
  object,
  field,
  defaultValue = {}
) => {
  const objectAlreadyContainsField = field in object;
  if (!objectAlreadyContainsField) object[field] = defaultValue;
};

export const reduceArrayIntoObject = (array, reducerCallback) =>
  array.reduce((object, value, index) => {
    reducerCallback(object, value, index);
    return object;
  }, {});

export const countOccurencesOnArray = (array, callbackPredicate) =>
  array.reduce(
    (amount, element) => (callbackPredicate(element) ? amount + 1 : amount),
    0
  );

const SINGLUAR_VALUES = [1, -1];
export const createPlural = (value, nominator) => {
  if (SINGLUAR_VALUES.includes(value)) return `${value} ${nominator}`;
  else return `${value} ${nominator}s`;
};

export function extractFieldWithRecursion(
  array,
  fieldToExtract,
  recursiveField,
  container = []
) {
  array.forEach((element) => {
    container.push(element[fieldToExtract]);
    extractFieldWithRecursion(
      element[recursiveField],
      fieldToExtract,
      recursiveField,
      container
    );
  });
  return container;
}

export function extractObjectPropertyInDepth(
  object,
  property,
  defaultValue = undefined
) {
  const allProperties = property.split(".");
  return allProperties.reduce((result, currentProperty) => {
    if (typeof result !== "object" || result === null) return defaultValue;
    return result[currentProperty];
  }, object);
}

export function triggerEvent(eventName, additionalData = {}) {
  const event = new Event(eventName);
  Object.entries(additionalData).forEach(([key, value]) => {
    Reflect.set(event, key, value);
  });
  document.dispatchEvent(event);
}

export function convertObjectToFormData(object) {
  if (typeof object !== "object")
    throw new Error("Expect an object to convert into form data.");

  const formData = new FormData();
  Object.entries(object).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return formData;
}
