import { calculateProgressPercentage } from "../../utils/DateUtils/DateUtils";
import {
  ADD_SCHEDULE_ACTIVITY_SUCCESS,
  ADD_SCHEDULE_ACTIVITY_ERROR,
  ADD_SCHEDULE_ACTIVITY_REQUEST,
  GET_SCHEDULE_ACTIVITIES_ERROR,
  GET_SCHEDULE_ACTIVITIES_REQUEST,
  GET_SCHEDULE_ACTIVITIES_SUCCESS,
  UPDATE_SCHEDULE_ACTIVITIES_REQUEST,
  UPDATE_SCHEDULE_ACTIVITIES_SUCCESS,
  UPDATE_SCHEDULE_ACTIVITIES_ERROR,
  UPDATE_SCHEDULE_ACTIVITY_REQUEST,
  UPDATE_SCHEDULE_ACTIVITY_SUCCESS,
  UPDATE_SCHEDULE_ACTIVITY_ERROR,
  UPDATE_DETAIL_ACTIVITY_REQUEST,
  UPDATE_DETAIL_ACTIVITY_SUCCESS,
  UPDATE_DETAIL_ACTIVITY_ERROR,
  CLEAR_SCHEDULE_ACTIVITIES,
  GET_REGULARS_REQUEST,
  GET_REGULARS_SUCCESS,
  GET_REGULARS_ERROR,
  UPDATE_SCHEDULE_ACTIVITIES_IMPORT_SCOPE_REQUEST,
  UPDATE_SCHEDULE_ACTIVITIES_IMPORT_SCOPE_SUCCESS,
  UPDATE_SCHEDULE_ACTIVITIES_IMPORT_SCOPE_ERROR,
  UPDATE_REGULAR,
  DELETE_SCHEDULE_ACTIVITY,
  DELETE_SCHEDULE_ACTIVITIES,
  UPLOAD_MPP_REQUEST,
  UPLOAD_MPP_SUCCESS,
  UPLOAD_MPP_ERROR,
  UPDATE_SCHEDULE_ACTIVITIES_BY_IDS,
  RESET_BASELINE_ON_ACTIVITY,
  RESET_BASELINE_ON_SUMMARY,
  ADD_SUBTASK_SUCCESS,
  ADD_SUMMARY_SUCCESS,
  APPLY_CONFORM_PROGRESS,
  GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMNS_SUCCESS,
  GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMNS_ERROR,
  GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_NAMES_REQUEST,
  GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_NAMES_SUCCESS,
  GET_RESOURCE_TASKS_CUSTOM_COLUMN_NAMES_ERROR,
  GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMNS_REQUEST,
  ADD_SCHEDULE_ACTIVITIES,
  UPDATE_SCHEDULE_ACTIVITY_FINISH_DATE_WITH_DURATION_SUCCESS,
  UPDATE_SCHEDULE_ACTIVITY_CUSTOM_COLUMN,
  UPDATE_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_VALUE,
  SHOW_MASK_ON_GANTT,
  UPDATE_FINISH_AND_START,
  PROPAGATE_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_VALUE,
} from "../types";
import produce from "immer";

const initialState = {
  item: {},
  items: [],
  regulars: [],
  customColumns: [],
  customColumnNames: [],
  showMaskOnGantt: false,
  isFetching: false,
  isFetchingRegulars: false,
  isUpdating: false,
  isFetchingColumns: false,
  isFetchingColumnNames: false,
  error: "",
};
// eslint-disable-next-line
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case ADD_SCHEDULE_ACTIVITY_REQUEST:
      return produce(state, (draftState) => {
        draftState.error = "";
      });
    case ADD_SCHEDULE_ACTIVITY_SUCCESS:
      const itemToAdd =
        "mappingTag" in payload
          ? { ...payload.scheduleActivity, mappingTag: payload.mappingTag }
          : payload;
      return produce(state, (draftState) => {
        draftState.items = [...draftState.items, itemToAdd];
      });
    case ADD_SCHEDULE_ACTIVITY_ERROR:
      return produce(state, (draftState) => {
        draftState.error = payload;
      });
    case ADD_SCHEDULE_ACTIVITIES:
      return produce(state, (draftState) => {
        const newItems = draftState.items.slice();
        newItems.push(...payload);
        draftState.items = newItems;
      });
    case UPDATE_DETAIL_ACTIVITY_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: "",
      };
    case UPDATE_DETAIL_ACTIVITY_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === payload.id) {
            return { ...item, ...payload };
          }
          return item;
        }),
        isUpdating: false,
      };
    case UPDATE_DETAIL_ACTIVITY_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    case UPDATE_SCHEDULE_ACTIVITY_REQUEST:
      return produce(state, (draftState) => {
        draftState.error = "";
        draftState.isUpdating = true;
      });
    case UPDATE_SCHEDULE_ACTIVITY_SUCCESS:
      return produce(state, (draftState) => {
        draftState.items = draftState.items.map((item) => {
          if (item.id === payload.id) {
            const { id, timeMetrics, ...rest } = payload;
            const updatedItem = { ...item, ...rest };
            if (timeMetrics) {
              const updatedTimeMetrics = {
                ...item.timeMetrics,
                ...timeMetrics,
              };
              updatedItem.timeMetrics = updatedTimeMetrics;
            }
            return updatedItem;
          }
          return item;
        });
        draftState.isUpdating = false;
      });
    case UPDATE_SCHEDULE_ACTIVITY_ERROR:
      return produce(state, (draftState) => {
        draftState.error = payload;
        draftState.isUpdating = false;
      });
    case UPDATE_SCHEDULE_ACTIVITIES_REQUEST:
      return produce(state, (draftState) => {
        draftState.error = "";
        draftState.isUpdating = true;
      });
    case UPDATE_SCHEDULE_ACTIVITIES_SUCCESS:
      return produce(state, (draftState) => {
        draftState.items = payload;
        draftState.isUpdating = false;
      });
    case UPDATE_SCHEDULE_ACTIVITIES_ERROR:
      return produce(state, (draftState) => {
        draftState.error = payload;
        draftState.isUpdating = false;
      });
    case UPLOAD_MPP_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
      });
    case UPLOAD_MPP_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.items = payload;
      });
    case UPLOAD_MPP_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.error = payload;
      });
    case UPDATE_SCHEDULE_ACTIVITIES_IMPORT_SCOPE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: "",
      };
    case UPDATE_SCHEDULE_ACTIVITIES_IMPORT_SCOPE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      };
    case UPDATE_SCHEDULE_ACTIVITIES_IMPORT_SCOPE_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    case GET_SCHEDULE_ACTIVITIES_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
        draftState.items = [];
        draftState.regulars = [];
        draftState.error = "";
      });
    case GET_SCHEDULE_ACTIVITIES_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.showMaskOnGantt = false;
        const newItems = payload.map(
          ({ scheduleActivity, hasConflicts, ...rest }) => ({
            ...scheduleActivity,
            globalCostAtCompletion: rest.globalCostAtCompletion || 0,
            laborCostAtCompletion: rest.laborCostAtCompletion || 0,
            spectrum: rest.spectrum || null,
            mappingTag: rest.mappingTag || null,
            hasConflicts,
          })
        );
        draftState.items = newItems;
      });
    case GET_SCHEDULE_ACTIVITIES_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.error = payload;
      });
    case DELETE_SCHEDULE_ACTIVITY:
      return produce(state, (draftState) => {
        draftState.items = draftState.items.filter(
          (item) => item.id !== payload
        );
      });
    case DELETE_SCHEDULE_ACTIVITIES:
      return produce(state, (draftState) => {
        draftState.items = draftState.items.filter(
          (item) => !payload.includes(item.id)
        );
      });
    case GET_REGULARS_REQUEST:
      return {
        ...state,
        items: [],
        isFetchingRegulars: true,
        regulars: [],
        error: "",
      };
    case GET_REGULARS_SUCCESS:
      return {
        ...state,
        regulars: payload,
        isFetchingRegulars: false,
      };
    case GET_REGULARS_ERROR:
      return {
        ...state,
        isFetchingRegulars: false,
        error: payload,
      };
    case UPDATE_REGULAR:
      return {
        ...state,
        regulars: state.regulars.map((item) => {
          if (item.scheduleActivity.id === payload.scheduleActivity.id) {
            return payload;
          }
          return item;
        }),
      };
    case UPDATE_SCHEDULE_ACTIVITIES_BY_IDS:
      return produce(state, (draftState) => {
        payload.forEach((item) => {
          const index = draftState.items.findIndex(
            (i) =>
              i.id === item.scheduleActivity.id ||
              i?.scheduleActivity?.id === item.scheduleActivity.id
          );
          item.scheduleActivity.hasConflicts = item.hasConflicts;
          if (
            "globalCostAtCompletion" in item &&
            "laborCostAtCompletion" in item
          ) {
            item.scheduleActivity.globalCostAtCompletion =
              item.globalCostAtCompletion;
            item.scheduleActivity.laborCostAtCompletion =
              item.laborCostAtCompletion;
          }
          draftState.items[index] = item.scheduleActivity;
        });
      });
    case RESET_BASELINE_ON_ACTIVITY:
      return produce(state, (draftState) => {
        draftState.items = draftState.items.map((item) =>
          item.id == payload.id ? payload : item
        );
      });
    case RESET_BASELINE_ON_SUMMARY:
      return produce(state, (draftState) => {
        const saMap = payload.reduce(
          (map, sa) => ({ ...map, [sa.id]: sa }),
          {}
        );
        draftState.items = draftState.items.map((item) => {
          if (item.id in saMap) return saMap[item.id];
          else return item;
        });
      });
    case ADD_SUBTASK_SUCCESS:
      return produce(state, (draftState) => {
        const { child, parent } = payload;
        const itemsWithParentUpdated = draftState.items.map((item) =>
          item.id === parent.id ? parent : item
        );
        draftState.items = [...itemsWithParentUpdated, child];
      });
    case ADD_SUMMARY_SUCCESS:
      const { parent, addedRegulars, summary } = payload;
      const addedTasks = [...addedRegulars, summary];
      return produce(state, (draftState) => {
        const itemsWithParentUpdated = draftState.items.map((item) =>
          item.id === parent.id ? parent : item
        );
        draftState.items = [...itemsWithParentUpdated, ...addedTasks];
      });
    case APPLY_CONFORM_PROGRESS:
      return produce(state, (draftState) => {
        draftState.items = draftState.items.map((item) => {
          if (payload.progressDTOs.includes(item.id)) {
            const progressPercentage = calculateProgressPercentage(
              item.earlyStartDate,
              item.earlyFinishDate,
              payload.dateOfReview
            );
            item.progress = progressPercentage;
          }
          return item;
        });
      });
    case GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMNS_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetchingColumns = true;
        draftState.error = "";
      });
    case GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMNS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetchingColumns = false;
        draftState.customColumns = payload || [];
      });
    case UPDATE_SCHEDULE_ACTIVITY_CUSTOM_COLUMN:
      return produce(state, (draftState) => {
        draftState.customColumns = draftState.customColumns.map((column) =>
          column.id === payload.scheduleActivityId
            ? { ...column, ...payload.data }
            : column
        );
      });
    case UPDATE_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_VALUE:
      return produce(state, (draftState) => {
        let updated = false;
        const newCustomColumns = draftState.customColumns.map((column) => {
          if (column.id === payload.id) {
            updated = true;
            return {
              ...column,
              [payload.columnKey]: payload.value,
            };
          } else return column;
        });
        if (!updated) {
          newCustomColumns.push({
            id: payload.id,
            [payload.columnKey]: payload.value,
          });
        }
        draftState.customColumns = newCustomColumns;
      });
    case GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMNS_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingColumns = false;
        draftState.error = payload;
      });
    case GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_NAMES_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetchingColumnNames = true;
        draftState.error = "";
      });
    case GET_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_NAMES_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetchingColumnNames = false;
        draftState.customColumnNames = payload || [];
      });
    case GET_RESOURCE_TASKS_CUSTOM_COLUMN_NAMES_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingColumnNames = false;
        draftState.error = payload;
      });
    case CLEAR_SCHEDULE_ACTIVITIES:
      return {
        ...state,
        items: [],
        tasks: [],
      };
    case UPDATE_SCHEDULE_ACTIVITY_FINISH_DATE_WITH_DURATION_SUCCESS:
      return {
        ...state,
        regulars: state.regulars.map((item) => {
          if (item.scheduleActivity.id === payload.id) {
            return {
              ...item,
              scheduleActivity: payload,
            };
          }
          return item;
        }),
      };
    case SHOW_MASK_ON_GANTT:
      return produce(state, (draftState) => {
        draftState.showMaskOnGantt = true;
      });
    case UPDATE_FINISH_AND_START:
      return produce(state, (draftState) => {
        draftState.regulars = draftState.regulars.map((item) => {
          if (item.scheduleActivity.id === payload.id) {
            item.scheduleActivity.startDate = payload.finishDate;
            item.scheduleActivity.finishDate = payload.finishDate;
          }
          return item;
        });
      });
    case PROPAGATE_SCHEDULE_ACTIVITY_CUSTOM_COLUMN_VALUE:
      return produce(state, (draftState) => {
        const nonExistingIds = payload.ids.filter((idToUpdate) =>
          draftState.customColumns.every(({ id }) => id != idToUpdate)
        );
        const updatedCustomColumns = draftState.customColumns.slice();
        // condition to avoid going through the array (which is sometimes big) when not necessary
        if (nonExistingIds.length !== payload.ids.length) {
          updatedCustomColumns.forEach((customColumn) => {
            const shouldPropagate = payload.ids.some(
              (id) => id == customColumn.id
            );
            if (shouldPropagate)
              customColumn[payload.customColumnField] = payload.value;
          });
        }
        nonExistingIds.forEach((id) => {
          const customColumn = { id };
          customColumn[payload.customColumnField] = payload.value;
          updatedCustomColumns.push(customColumn);
        });
        draftState.customColumns = updatedCustomColumns;
      });
    default:
      return state;
  }
}
